import {
  getCustomer,
  getStaff,
  getTransactions,
  getUser,
} from "@/services/rightsector-service";

const rightsectorModule = {
  namespaced: true,
  state: {
    customer: {},
    staff: {},
    depositLists: [],
    withdrawLists: [],
    userLists: [],
    bankMessage: "",
    isNormalDeposit: true,
  },
  mutations: {
    RESET_STATE(state) {
      state.customer = {};
      state.staff = {};
    },

    SET_BANKMESSAGE_DATA(state, newBankMessageData) {
      state.bankMessage = newBankMessageData;
    },

    SET_CUSTOMER_DATA(state, newCustomerData) {
      state.customer = newCustomerData;
    },

    SET_STAFF_DATA(state, newStaffData) {
      state.staff = newStaffData;
    },

    SET_DEPOSITLISTS_DATA(state, newListsData) {
      state.depositLists = newListsData;
    },

    SET_WITHDRAWLISTS_DATA(state, newListsData) {
      state.withdrawLists = newListsData;
    },

    SET_USERLISTS_DATA(state, newUserData) {
      state.userLists = newUserData;
    },

    SET_ISNORMALDEPOSIT_DATA(state, isNormalDepositData) {
      state.isNormalDeposit = isNormalDepositData;
    },

    MERGED_DEPOSITLIST_DATA(state, newListsData) {
      state.depositLists.unshift(newListsData);
    },

    MERGED_WITHDRAWLIST_DATA(state, newListsData) {
      state.withdrawLists.unshift(newListsData);
    },
  },
  actions: {
    async getCustomer(context, id) {
      const data = `${id}`;
      const response = await getCustomer(data);
      console.log("GET CUSTOMER", response);
      context.commit("SET_CUSTOMER_DATA", response.data);
    },

    async getStaff(context) {
      const response = await getStaff();
      console.log("GET STAFF", response);
      context.commit("SET_STAFF_DATA", response.data);
    },

    async getDepositLists(context, id) {
      const req = `?customer_id=${id}&type=200`;
      const response = await getTransactions(req);
      console.log("GET DEPOSIT", response);
      context.commit("SET_DEPOSITLISTS_DATA", response.data);
    },

    async getWithdrawLists(context, id) {
      const req = `?customer_id=${id}&type=400`;
      const response = await getTransactions(req);
      console.log("GET WITHDRAW", response);
      context.commit("SET_WITHDRAWLISTS_DATA", response.data);
    },

    async getUserLists(context, id) {
      const response = await getUser(id);
      console.log("GET USER", response);
      context.commit("SET_USERLISTS_DATA", response.data);
    },

    setCustomer(context, data) {
      context.commit("SET_CUSTOMER_DATA", data);
    },
  },
};

export default rightsectorModule;
