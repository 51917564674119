import { request } from "./api";

export function Login(data) {
  const url = `/api/chatbot/login`;
  return request("post", url, data);
}

export function Logout(data) {
  const url = `/api/chatbot/logout`;
  return request("Delete", url, data, localStorage.getItem("token"));
}

export function verifyOtp(data) {
  const url = `/api/chatbot/verify`;
  return request("post", url, data);
}

export function getNewMessage(data) {
  const url = `/api/chatbot/rooms${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function getChattingMessage(data) {
  const url = `/api/chatbot/rooms${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function setRoomWork(data) {
  const url = `/api/chatbot/work-room/${data}`;
  return request("post", url, {}, localStorage.getItem("token"));
}

export function getWebs() {
  const url = `/api/chatbot/webs`;
  return request("get", url, {}, localStorage.getItem("token"));
}
