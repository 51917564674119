<template>
  <div class="col-3 q-gutter-y-md" style="width: 20% !important">
    <div class="left-sector h-top">
      <div
        @click="handleClickTest"
        ref="newMessageheader"
        class="new-message-header font-bolds"
      >
        ข้อความติดต่อใหม่
      </div>
      <q-scroll-area :style="{ height: setNewMessageHeight() }">
        <div class="flex justify-center q-gutter-y-sm q-mt-none q-pb-sm">
          <div
            v-for="(newMessage, index) in newMessages"
            :key="index"
            class="new-message-item cursor-pointer"
            @click="handleClickPreview(newMessage.id, newMessage.web_id)"
          >
            <div
              ref="newMessageItem"
              :id="'newMessageItem' + newMessage.customer.id"
              class="row items-center font-regular q-col-gutter-x-sm"
              style="width: calc(100% - 50px)"
            >
              <div
                class="flex items-center q-gutter-x-sm"
                :style="{
                  width:
                    setNewMessageWidth(newMessage.customer.id) === true
                      ? 'calc(100% - 30px)'
                      : '',
                }"
              >
                <q-avatar size="md">
                  <img :src="newMessage.customer.picture_url" />
                </q-avatar>
                <div
                  class="text-ellipsis"
                  :style="{
                    width:
                      setNewMessageWidth(newMessage.customer.id) === true
                        ? 'calc(100% - 48px)'
                        : '',
                  }"
                >
                  <span
                    ref="newTextItem"
                    :id="'newTextItem' + newMessage.customer.id"
                    >{{ newMessage.customer.display_name }}</span
                  >
                </div>
              </div>
              <div class="">
                <q-avatar
                  v-if="newMessage.is_read === false"
                  size="xs"
                  color="red"
                  text-color="white"
                  class="text-avatar"
                  style="font-size: 21px"
                  >N</q-avatar
                >
              </div>
            </div>
            <q-btn
              @click.stop="handleClickGetWork(newMessage.id, customer)"
              unelevated
              color="white"
              text-color="dark-green"
              label="รับ"
              class="btn-radius font-semibold"
            />
          </div>
        </div>
      </q-scroll-area>
    </div>
    <div class="left-sector h-bottom">
      <div ref="chattingheader" class="chatting-message-header font-bolds">
        <q-btn
          @click="isShowTab = !isShowTab"
          unelevated
          size="sm"
          color="white"
          text-color="black"
          icon="list"
          :label="
            viewMode === 'all'
              ? 'ทั้งหมด'
              : viewMode === 'pin'
              ? 'ปักหมุด'
              : viewMode === 'unread'
              ? 'ยังไม่ได้อ่าน'
              : viewMode === 'process'
              ? 'รอติดตาม'
              : viewMode === 'end'
              ? 'จบการสนทนา'
              : 'ดูแชททั้งหมด'
          "
          class="btn-radius font-semibold q-mr-sm"
        />
        <span class="text-small">ข้อความที่กำลังสนทนา</span>
        <div
          ref="chattingSort"
          v-show="isShowTab === true"
          class="lists-tabs left font-regular"
        >
          <div @click="handleClickSort('all')" class="list-item">
            <q-icon name="view_module" size="xs"></q-icon>
            <span> ทั้งหมด</span>
          </div>
          <div @click="handleClickSort('unread')" class="list-item">
            <q-icon name="email" size="xs"></q-icon>
            <span> ยังไม่ได้อ่าน</span>
          </div>
          <div @click="handleClickSort('pin')" class="list-item">
            <q-icon name="push_pin" size="xs"></q-icon>
            <span> ปักหมุด</span>
          </div>
          <div @click="handleClickSort('process')" class="list-item">
            <q-icon name="message" size="xs"></q-icon>
            <span> รอติดตาม</span>
          </div>
          <div @click="handleClickSort('end')" class="list-item">
            <q-icon name="check_circle" size="xs"></q-icon>
            <span> จบการสนทนา</span>
          </div>
          <div @click="handleClickSort('overall')" class="list-item">
            <q-icon name="library_books" size="xs"></q-icon>
            <span> ดูแชททั้งหมด</span>
          </div>
        </div>
      </div>
      <q-scroll-area :style="{ height: setChattingHeight() }">
        <div class="flex justify-center q-gutter-y-sm q-mt-none q-pb-sm">
          <div
            v-if="viewMode === 'overall' || viewMode === 'end'"
            class="width-90 q-gutter-y-sm"
          >
            <q-input
              @keydown.enter="handleClickSearchChat(web_id)"
              dense
              filled
              v-model="searchText"
              bg-color="white-grey"
              color="black"
              class="font-regular full-width q-mb-md q-mt-sm"
              label="พิมพ์ชื่อหรือไอดีเพื่อค้นหา"
            >
              <template v-slot:append>
                <q-icon
                  name="search"
                  @click="handleClickSearchChat(web_id)"
                  class="cursor-pointer"
                />
              </template>
            </q-input>
            <q-separator class="q-mb-md" />
            <ChattingMessage
              v-if="searchMessages.length > 0"
              :chattingMessages="searchMessages"
              :customer="customer"
              :viewMode="viewMode"
              :staff="staff"
              condition="false"
            />
            <div
              v-else
              class="absolute-center text-grey-95 font-regular"
              style="font-size: 1.15rem"
            >
              {{ isLoading === true ? "กำลังโหลดข้อมูล" : "ไม่มีรายการ" }}
            </div>
          </div>
          <ChattingMessage
            :chattingMessages="chattingMessages"
            :customer="customer"
            :viewMode="viewMode"
            :staff="staff"
            condition="true"
          />
          <div
            v-if="
              (viewMode === 'all' && chattingMessages.length <= 0) ||
              (viewMode === 'pin' &&
                chattingMessages.find((item) => item.is_pin === true) ===
                  undefined) ||
              (viewMode === 'unread' &&
                chattingMessages.find((item) => item.is_read === false) ===
                  undefined) ||
              (viewMode === 'process' &&
                chattingMessages.find((item) => item.is_process === true) ===
                  undefined)
            "
            class="absolute-center full-width"
          >
            <div class="not-chat font-regular text-center">
              <q-icon name="textsms" size="lg"></q-icon>
              <p>ข้อความที่คุณรับจะแสดงที่นี่</p>
            </div>
          </div>
          <!-- <div
            v-for="(chattingMessage, index) in chattingMessages"
            :key="index"
            class="row chatting-message-item cursor-pointer q-ml-none"
            :class="customer.id === chattingMessage.id ? 'read ' : ''"
            @click="
              handleClickGetChat(
                chattingMessage.id,
                chattingMessage.web_id,
                chattingMessage.staff_id
              )
            "
            v-show="
              (viewMode === 'all' && chattingMessage.staff_id === staff.id) ||
              (viewMode === 'pin' &&
                chattingMessage.is_pin === true &&
                chattingMessage.staff_id === staff.id) ||
              (viewMode === 'unread' &&
                chattingMessage.is_read === false &&
                chattingMessage.staff_id === staff.id) ||
              (viewMode === 'process' &&
                chattingMessage.is_process === true &&
                chattingMessage.staff_id === staff.id) ||
              (viewMode === 'end' && chattingMessage.staff_id !== staff.id)
            "
          >
            <div class="col-2 flex items-center q-pl-none">
              <q-avatar size="lg" class="relative-position">
                <img :src="chattingMessage.customer.picture_url" />
                <q-icon
                  v-if="chattingMessage.is_pin === true"
                  class="absolute-bottom-right"
                  name="push_pin"
                  size="xs"
                  color="dark-green"
                ></q-icon>
              </q-avatar>
            </div>
            <div class="col-10">
              <div class="font-semibold q-gutter-x-xs">
                <span class="text-normal">{{
                  chattingMessage.customer.display_name
                }}</span>
                <q-avatar
                  v-if="chattingMessage.is_process === true"
                  size="xs"
                  color="dark-blue"
                  text-color="white"
                  >W</q-avatar
                >
                <q-avatar
                  v-if="chattingMessage.is_read === false"
                  size="xs"
                  color="red"
                  text-color="white"
                  >N</q-avatar
                >
              </div>
              <div class="font-regular row">
                <div class="col-1 flex self-center"></div>
                <div class="col-12 text-ellipsis flex items-center">
                  <q-icon
                    v-if="
                      chattingMessage.last_message !== null &&
                      chattingMessage.last_message.staff_id === null &&
                      chattingMessage.last_message.is_bot === false
                    "
                    name="account_circle"
                    color="main-red"
                  />
                  <span class="text-ellipsis q-pl-xs">{{
                    chattingMessage.last_message !== null
                      ? chattingMessage.last_message.message.type === "text"
                        ? chattingMessage.last_message.message.text
                        : chattingMessage.last_message.message.type === "flex"
                        ? chattingMessage.last_message.message.altText
                        : chattingMessage.last_message.message.type ===
                          "sticker"
                        ? "[ สติ๊กเกอร์ ]"
                        : chattingMessage.last_message.message.type === "image"
                        ? "[ รูปภาพ ]"
                        : chattingMessage.last_message.message.type === "html"
                        ? "[ ข้อความกลาง ]"
                        : "[ Unsupport Message ]"
                      : "[ Unsupport Message ]"
                  }}</span>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </q-scroll-area>
      <!-- <div
        v-else
        class="flex justify-center items-center"
        :style="{ height: setChattingHeight() }"
      >
        <div class="not-chat font-regular text-center">
          <q-icon name="textsms" size="lg"></q-icon>
          <p>ข้อความที่คุณรับจะแสดงที่นี่</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { utilily } from "@/utilities/utility";
import { getChattingMessage } from "@/services/leftsector-service";
import ChattingMessage from "./ChattingMessage.vue";
import { useRouter } from "vue-router";

export default {
  props: [
    "newMessages",
    "chattingMessages",
    "searchMessages",
    "customer",
    "staff",
    "web_id",
  ],
  components: { ChattingMessage },
  setup() {
    const { getWork, getChat, isEmpty } = utilily();

    const newMessageheader = ref(null);
    const chattingheader = ref(null);
    const chattingSort = ref(null);
    const newMessageItem = ref([]);
    const newTextItem = ref([]);

    const store = useStore();
    const router = useRouter();
    const state = reactive({
      isShowTab: false,
      viewMode: "all",
      searchText: "",
      isLoading: false,
    });

    onMounted(() => {
      document.addEventListener("click", handleClickOutSide);
    });

    const handleClickOutSide = (event) => {
      if (
        chattingSort.value &&
        !chattingSort.value.contains(event.target) &&
        chattingheader.value &&
        !chattingheader.value.contains(event.target)
      ) {
        state.isShowTab = false;
      }
    };

    const setNewMessageHeight = () => {
      const height = `calc(100% - ${
        newMessageheader.value !== null
          ? newMessageheader.value.clientHeight
          : 0
      }px )`;
      return height;
    };

    const setNewMessageWidth = (id) => {
      const elemRow = newMessageItem.value.find(
        (obj) => obj.id === "newMessageItem" + id
      );
      const elemText = newTextItem.value.find(
        (obj) => obj.id === "newTextItem" + id
      );
      if (elemRow !== undefined || elemText !== undefined) {
        if (elemRow.offsetWidth - 78 < elemText.offsetWidth) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    const setChattingHeight = () => {
      const height = `calc(100% - ${
        chattingheader.value !== null ? chattingheader.value.clientHeight : 0
      }px )`;
      return height;
    };

    const handleClickPreview = async (id, web_id) => {
      await store.dispatch("rightsectorModule/getCustomer", id);
      await store.dispatch("rightsectorModule/getUserLists", id);
      store.commit("centersectorModule/SET_LOADINGCHAT_DATA", true);
      await store.dispatch("centersectorModule/getRoom", {
        id: id,
        web_id: web_id,
      });

      const element = document.querySelector(
        "#scroll-sector .q-scrollarea__container"
      );
      element.scrollTop = element.scrollHeight;
      store.commit("centersectorModule/SET_LOADINGCHAT_DATA", false);
      await store.dispatch("centersectorModule/setPreview", true);
    };

    const handleClickGetWork = async (id, customer) => {
      getWork(id);
      if (isEmpty(customer) === false && customer.id === id) {
        await store.dispatch("centersectorModule/setPreview", false);
      }
    };

    const handleClickGetChat = async (id, web_id, staff_id) => {
      getChat(id, web_id, staff_id);
    };

    const handleClickSort = (sorted) => {
      state.isShowTab = false;
      state.viewMode = sorted;
      store.commit("leftsectorModule/SET_VIEWMODE_DATA", sorted);
      state.searchText = "";
      store.commit("leftsectorModule/SET_SEARCHMESSAGE_DATA", []);
    };

    const handleClickSearchChat = async (web_id) => {
      var data = `?name=${state.searchText}`;
      if (web_id !== null && web_id !== "undefined" && parseInt(web_id) !== 0) {
        data += `&web_id=${web_id}`;
      }
      state.isLoading = true;
      const response = await getChattingMessage(data);
      state.isLoading = false;
      console.log(response);
      if (response.status === "success") {
        store.commit("leftsectorModule/SET_SEARCHMESSAGE_DATA", response.data);
      }
    };

    const handleClickTest = () => {
      router.go();
    };

    return {
      newMessageheader,
      chattingheader,
      chattingSort,
      newMessageItem,
      newTextItem,
      ...toRefs(state),
      setNewMessageHeight,
      setNewMessageWidth,
      setChattingHeight,
      handleClickPreview,
      handleClickGetWork,
      handleClickGetChat,
      handleClickSort,
      handleClickSearchChat,
      handleClickTest,
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-dark-blue {
  background-color: $dark-blue;
}
.bg-dark-green {
  background-color: $dark-green;
}
.text-dark-green {
  color: $dark-green;
}
.text-main-red {
  color: $main-red;
}
.left-sector {
  background-color: white;
  border-radius: 0 1rem 1rem 0;
  &.h-top {
    height: calc(40% - 16px);
  }
  &.h-bottom {
    height: calc(60% - 16px);
  }
  & .not-chat {
    color: $light-grey;
  }
  & .new-message-header {
    padding: 0.75rem;
    text-align: center;
    color: white;
    background: $gd-green;
    border-radius: 0 1rem 1rem 0;
  }
  & .new-message-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    color: black;
    background-color: $white-grey;
    padding: 0.5rem;
    border-radius: 0.75rem;
    & .profile-image {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
    }
  }
  & .chatting-message-header {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.75rem 0.25rem;
    color: white;
    background: $gd-green;
    border-radius: 0 1rem 1rem 0;
  }
}
</style>
