<template>
  <q-dialog v-model="isDeposit" persistent seamless>
    <q-card class="financialDialog" style="width: 500px; max-width: 70vw">
      <q-card-section
        class="row justify-center items-center header green font-semibold q-pa-sm"
      >
        <div class="text-h6">แจ้งฝากเงิน</div>
        <q-btn
          @click="handleCloseDialog"
          class="absolute-right"
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>

      <q-card-section
        class="row q-col-gutter-x-md"
        :class="viewer !== null ? 'q-ml-none' : ''"
      >
        <div
          @dragover.prevent
          @drop="onDrop"
          class="col font-regular"
          style="min-height: 400px"
        >
          <img
            v-show="uploadedFile && viewer === null"
            :src="uploadedFile"
            class="full-width full-height"
            id="img-preview"
          />
          <div
            ref="dropzone"
            v-show="!uploadedFile && viewer === null"
            id="dropzone"
            class="full-height dropzone"
          ></div>
        </div>
        <q-form
          class="col q-gutter-y-md font-regular relative-position form-input"
          @submit="
            handleClickSendSlip(
              isNormalDeposit === true
                ? true
                : uploadedFile !== null
                ? true
                : false,
              isNormalDeposit
            )
          "
        >
          <div>
            <q-select
              @update:model-value="handleUserChange"
              dense
              filled
              v-model="user"
              bg-color="white"
              color="grey"
              :options="userOptions"
              label="เลือก User"
              emit-value
              map-options
              :rules="[selectRule]"
            />
          </div>
          <div v-if="isNormalDeposit === true" class="q-mt-none">
            <q-select
              dense
              filled
              v-model="userAccount"
              :class="userAccountOptions.length <= 0 ? 'disable' : ''"
              bg-color="white"
              color="grey"
              :options="userAccountOptions"
              label="โอนจากบัญชี"
              emit-value
              map-options
              :disable="userAccountOptions.length <= 0 ? true : false"
              :rules="[selectRule]"
            />
          </div>
          <div v-if="isNormalDeposit === true" class="q-mt-none">
            <q-select
              dense
              filled
              v-model="account"
              :class="accountOptions.length <= 0 ? 'disable' : ''"
              bg-color="white"
              color="grey"
              :options="accountOptions"
              label="โอนเข้าบัญชี"
              emit-value
              map-options
              :disable="accountOptions.length <= 0 ? true : false"
              :rules="[selectRule]"
            />
          </div>
          <div v-if="isNormalDeposit === true" class="q-mt-none">
            <q-input
              dense
              filled
              v-model="amount"
              bg-color="white"
              color="black"
              label="ยอดเงิน"
              :rules="[numberRule]"
            />
          </div>
          <div v-if="isNormalDeposit === true" class="q-mt-none">
            <q-input
              filled
              dense
              v-model="date"
              bg-color="white"
              color="black"
              :rules="[dateRule]"
            >
              <template v-slot:prepend>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="date"
                      mask="YYYY-MM-DD HH:mm"
                      color="green"
                      :options="optionsDate"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>

              <template v-slot:append>
                <q-icon name="access_time" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-time
                      v-model="date"
                      mask="YYYY-MM-DD HH:mm"
                      color="green"
                      format24h
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-time>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="q-mt-none">
            <q-input
              dense
              v-model="note"
              bg-color="white"
              color="black"
              filled
              label="หมายเหตุ"
              type="textarea"
            />
          </div>
          <div
            class="row q-col-gutter-x-md"
            :class="
              isNormalDeposit === false ? 'absolute-bottom q-ml-none' : ''
            "
          >
            <div class="col">
              <q-btn
                @click="handleCloseDialog"
                v-close-popup
                unelevated
                color="white"
                text-color="black"
                label="ยกเลิก"
                size="md"
                class="btn-radius full-width"
              />
            </div>
            <div class="col">
              <q-btn
                type="submit"
                unelevated
                :color="
                  isNormalDeposit === true
                    ? user !== '' &&
                      userAccount !== '' &&
                      account !== '' &&
                      amount !== '' &&
                      date !== ''
                      ? 'gd-green'
                      : 'light-grey'
                    : uploadedFile !== null && user !== ''
                    ? 'gd-green'
                    : 'light-grey'
                "
                text-color="white"
                label="แจ้งโอนเงิน"
                size="md"
                class="btn-radius full-width"
              />
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
  <q-dialog v-model="isLoading" persistent>
    <q-card class="loading-dialog font-regular">
      <q-card-section> กำลังส่งข้อมูล ...</q-card-section>
    </q-card>
  </q-dialog>
  <ExceptionDialog
    :isException="isException"
    :exceptionMessage="exceptionMessage"
    @handleCloseDialog="handleCloseExceptionDialog"
  />
</template>

<script>
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import { getBankLists, transactions } from "@/services/rightsector-service";
import { useStore } from "vuex";
import ExceptionDialog from "./ExceptionDialog.vue";
import { utilily } from "@/utilities/utility";

export default {
  props: ["isDeposits", "customer", "isNormalDeposit"],
  emits: ["handleCloseDialog"],
  components: { ExceptionDialog },
  setup(props, { emit }) {
    const dropzone = ref(null);

    const store = useStore();

    const BASE_API_URL = process.env.VUE_APP_API_URL
      ? process.env.VUE_APP_API_URL
      : "https://office.chatbot.softbot.space";
    const { optionsDate, numberRule, dateRule, selectRule } = utilily();

    const state = reactive({
      isDeposit: false,
      isLoading: false,
      user: "",
      userAccount: "",
      account: "",
      amount: "",
      date: "",
      note: "",
      userLists: computed(() => store.state.rightsectorModule.userLists),
      userOptions: [],
      userAccountOptions: [],
      accountOptions: [],
      uploadedFile: null,
      dropzone: null,
      viewer: null,
      isException: false,
      exceptionMessage: "",
    });

    watch(
      () => props.isDeposits,
      (isDeposit) => {
        state.isDeposit = isDeposit;
        if (isDeposit === true) {
          initDropzone();
          initUser();
          const currentDate = new Date();
          state.date = formatDate(currentDate);
        } else {
          state.uploadedFile = null;
          state.viewer = null;
          state.userOptions = [];
          state.accountOptions = [];
          state.userAccountOptions = [];
          state.user = "";
          state.userAccount = "";
          state.account = "";
          state.amount = "";
          state.date = "";
          state.note = "";
        }
      }
    );

    onMounted(() => {
      document.addEventListener("paste", handlePaste);
    });

    onUnmounted(() => {
      document.removeEventListener("paste", handlePaste);
    });

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const initDropzone = () => {
      setTimeout(() => {
        const element = document.querySelector("#dropzone");

        state.dropzone = new Dropzone(element, {
          url: `${BASE_API_URL}/api/chatbot/uploadimage`,
          acceptedFiles: "image/*",
          addRemoveLinks: true,
          paramName: "image",
          dictDefaultMessage: "วางรูปภาพ",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });

        state.dropzone.on("success", (file, response) => {
          console.log("Uploaded", response);
          state.uploadedFile = response.data.image_url;
          setTimeout(() => {
            state.viewer = new Viewer(document.getElementById("img-preview"), {
              inline: true,
              navbar: false,
              title: false,
              button: false,
              backdrop: false,
              toolbar: {
                rotateLeft: true,
                rotateRight: true,
              },
            });
          }, 100);
        });
      }, 250);
    };

    const initUser = async () => {
      state.userLists.forEach((element) => {
        state.userOptions.push({ label: element.user, value: element.id });
      });
      if (state.userOptions.length === 1) {
        handleUserChange(state.userOptions[0].value);
      }
    };

    const handleUserChange = async (value) => {
      state.user = value;
      state.userAccount = "กำลังโหลดข้อมูล";
      state.account = "กำลังโหลดข้อมูล";
      const response = await getBankLists(state.user);
      state.userAccount = "";
      state.account = "";
      state.userAccountOptions = [];
      state.accountOptions = [];
      if (response.status === "success") {
        response.data.fromBank.forEach((element) => {
          state.userAccountOptions.push({
            label: element.bankName + " " + element.accNumber,
            value: element.id,
          });
        });
        if (state.userAccountOptions.length === 1) {
          state.userAccount = state.userAccountOptions[0].value;
        }

        response.data.toBank.forEach((element) => {
          state.accountOptions.push({
            label: element.bankName + " " + element.accNumber,
            value: element.id,
          });
        });
        if (state.accountOptions.length === 1) {
          state.account = state.accountOptions[0].value;
        }
      } else if (response.status === "http_error") {
        state.userAccount = "ไม่พบบัญชีธนาคารในระบบ";
        state.account = "ไม่พบบัญชีธนาคารในระบบ";
        state.isException = true;
        state.exceptionMessage = response.message;
      } else {
        state.userAccount = "ไม่พบบัญชีธนาคารในระบบ";
        state.account = "ไม่พบบัญชีธนาคารในระบบ";
        state.isException = true;
        state.exceptionMessage =
          response.data?.descHtml !== undefined
            ? response.data.descHtml
            : response.message;
      }
    };

    const handlePaste = (event) => {
      var items = (event.clipboardData || window.clipboardData).items;
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          var file = items[i].getAsFile();
          state.dropzone.addFile(file);
        }
      }
    };

    const handleCloseDialog = () => {
      emit("handleCloseDialog");
    };

    const handleClickSendSlip = async (validate, isNormalDeposit) => {
      if (validate === false) {
        return;
      }
      const userById = state.userLists.find((obj) => obj.id === state.user);
      var data;
      if (isNormalDeposit === true) {
        data = {
          type: "200",
          user: userById.user,
          image_url: state.uploadedFile,
          from_bank_acc: state.userAccount,
          bank_acc: state.account,
          amount: parseFloat(state.amount).toFixed(2),
          transaction_time: Date.parse(state.date) / 1000,
          note: state.note,
        };
      } else {
        data = {
          type: "200",
          user: userById.user,
          image_url: state.uploadedFile,
          transaction_time: Math.floor(Date.now() / 1000),
          note: state.note,
        };
      }

      console.log("slip data", data);
      handleCloseDialog();
      state.isLoading = true;
      const response = await transactions(data);
      console.log(response);
      state.isLoading = false;
      if (response.status === "success") {
        state.isDeposit = false;
      } else if (response.status === "http_error") {
        state.isException = true;
        state.exceptionMessage = response.message;
      } else {
        state.isException = true;
        state.exceptionMessage =
          response.data?.descHtml !== undefined
            ? response.data.descHtml
            : response.message;
      }
    };

    const onDrop = (event) => {
      event.preventDefault();
      const data = event.dataTransfer.getData("text/plain");
      const draggedElement = document.getElementById(data);
      if (draggedElement !== null && draggedElement.alt === "dragable") {
        fetch(draggedElement.getAttribute("data-original"))
          .then((response) => response.blob())
          .then((blob) => {
            // Create a file object from the blob
            const file = new File([blob], "image.jpg", { type: "image/jpeg" });
            console.log(file);
            state.dropzone.addFile(file);
            // Use the file object as needed
          })
          .catch((error) => console.error(error));
      }
    };

    const handleCloseExceptionDialog = () => {
      state.isException = false;
    };

    return {
      ...toRefs(state),
      handleUserChange,
      handlePaste,
      handleCloseDialog,
      handleClickSendSlip,
      onDrop,
      dropzone,
      handleCloseExceptionDialog,
      optionsDate,
      numberRule,
      dateRule,
      selectRule,
    };
  },
};
</script>

<style lang="scss" scoped></style>
