import { createStore } from "vuex";
import leftsectorModule from "./leftsector/leftsector-module";
import centersectorModule from "./centersector/centersector-module";
import rightsectorModule from "./rightsector/rightsector-module";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: { leftsectorModule, centersectorModule, rightsectorModule },
});
