import { createRouter, createWebHistory } from "vue-router";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import Home from "../views/home/Home.vue";
import Login from "../views/login/Login.vue";

const routes = [
  {
    path: "/",
    name: "layout",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "homePage",
        component: Home,
        meta: { requireAuth: true },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    const token = localStorage.getItem("token");
    if (token === null) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
