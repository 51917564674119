import axios from "axios";
import router from "@/router/index";

const BASE_API_URL = process.env.VUE_APP_API_URL
  ? process.env.VUE_APP_API_URL
  : "https://office.chatbot.softbot.space";
console.log("url", BASE_API_URL);
const http = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export async function request(method, url, data, token = null) {
  var response;
  let headers = {};
  if (token) {
    const header_auth = {
      Authorization: "Bearer " + token,
    };

    headers = {
      ...header_auth,
    };
  }
  try {
    response = await http({
      method,
      url,
      data,
      headers,
    });
  } catch (error) {
    console.log("error ->>", error.response);
    if (error.response.status === 401) {
      router.replace({ name: "login" });
      if (url === "/api/chatbot/login") {
        return { status: "error" };
      }
    }
    return {
      status: "http_error",
      message: "HTTP Error " + error.response.status,
    };
  }
  return response.data;
}
export async function requestImg(method, url, data, token = null) {
  var response;
  let headers = {};
  if (token) {
    const header_auth = {
      Authorization: "Bearer " + token,
    };

    headers = {
      ...header_auth,
      "Accept-Encoding": "gzip, deflate, br",
    };
  }
  try {
    response = await http({
      method,
      url,
      data,
      headers,
      responseType: "arraybuffer",
    });
    // return `data:${
    //   response.headers["content-type"]
    // };charset=utf-8;base64,${btoa(
    //   String.fromCharCode(...new Uint8Array(response.data))
    // )}`;

    // const base64flag = `data:${response.headers["content-type"]};base64,`;
    // const base64string = Buffer.from(response.data, "binary").toString(
    //   "base64"
    // );
    // return `${base64flag}${base64string}`;
    // return response.data;
    let blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    let image = URL.createObjectURL(blob);
    return image;
  } catch (error) {
    console.log("error ->>", error.response);
    if (error.response.status === 401) {
      console.log("ERROR 401");
    }
  }
  // console.log(response);
  // return response.imgData;
}
