<template>
  <q-dialog v-model="isWithdraw" persistent seamless>
    <q-card class="financialDialog" style="width: 300px; max-width: 30vw">
      <q-card-section
        class="row justify-center items-center header red font-semibold q-pa-sm"
      >
        <div class="text-h6">แจ้งถอนเงิน</div>
        <q-btn
          @click="handleCloseDialog"
          class="absolute-right"
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>

      <q-card-section class="row q-col-gutter-x-md">
        <q-form
          class="col q-gutter-y-md font-regular form-input"
          @submit="handleClickSendWithdraw()"
        >
          <div>
            <q-select
              @update:model-value="handleUserChange"
              dense
              filled
              v-model="user"
              bg-color="white"
              color="grey"
              :options="userOptions"
              label="เลือก User"
              emit-value
              map-options
              :rules="[selectRule]"
            />
          </div>
          <div class="q-mt-none">
            <q-select
              dense
              filled
              v-model="bank"
              :class="bankOptions.length <= 0 ? 'disable' : ''"
              bg-color="white"
              color="grey"
              :options="bankOptions"
              label="ธนาคาร"
              emit-value
              map-options
              :disable="bankOptions.length > 0 ? false : true"
              :rules="[selectRule]"
            />
          </div>
          <div class="q-mt-none">
            <q-input
              dense
              filled
              v-model="amount"
              bg-color="white"
              color="black"
              label="ยอดเงิน"
              :rules="[numberRule]"
            />
          </div>
          <div class="q-mt-none">
            <q-input
              dense
              v-model="note"
              bg-color="white"
              color="black"
              filled
              label="หมายเหตุ"
              type="textarea"
            />
          </div>
          <div class="row q-col-gutter-x-md">
            <div class="col">
              <q-btn
                @click="handleCloseDialog"
                v-close-popup
                unelevated
                color="white"
                text-color="black"
                label="ยกเลิก"
                size="md"
                class="btn-radius full-width"
              />
            </div>
            <div class="col">
              <q-btn
                type="submit"
                unelevated
                :color="
                  user !== '' && bank !== '' && amount !== ''
                    ? 'gd-red'
                    : 'light-grey'
                "
                text-color="white"
                label="แจ้งถอนเงิน"
                size="md"
                class="btn-radius full-width"
              />
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
  <q-dialog v-model="isLoading">
    <q-card class="loading-dialog font-regular">
      <q-card-section> กำลังส่งข้อมูล ...</q-card-section>
    </q-card>
  </q-dialog>
  <ExceptionDialog
    :isException="isException"
    :exceptionMessage="exceptionMessage"
    @handleCloseDialog="handleCloseExceptionDialog"
  />
</template>

<script>
import { computed, reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { getUserBank, transactions } from "@/services/rightsector-service";
import ExceptionDialog from "./ExceptionDialog.vue";
import { utilily } from "../../utilities/utility";

export default {
  props: ["isWithdraws"],
  emits: ["handleCloseDialog"],
  components: { ExceptionDialog },
  setup(props, { emit }) {
    const store = useStore();

    const { numberRule, dateRule, selectRule } = utilily();

    const state = reactive({
      isWithdraw: false,
      isLoading: false,
      user: "",
      bank: "",
      amount: "",
      note: "",
      userLists: computed(() => store.state.rightsectorModule.userLists),
      userOptions: [],
      bankOptions: [],
      isException: false,
      exceptionMessage: "",
    });

    watch(
      () => props.isWithdraws,
      (isWithdraw) => {
        state.isWithdraw = isWithdraw;
        if (isWithdraw === true) {
          initUser();
        } else {
          state.userOptions = [];
          state.bankOptions = [];
          state.user = "";
          state.bank = "";
          state.amount = "";
          state.note = "";
        }
      }
    );

    const initUser = async () => {
      state.userLists.forEach((element) => {
        state.userOptions.push({ label: element.user, value: element.id });
      });
      if (state.userOptions.length === 1) {
        handleUserChange(state.userOptions[0].value);
      }
    };

    const handleCloseDialog = () => {
      emit("handleCloseDialog");
    };

    const handleUserChange = async (value) => {
      state.user = value;
      state.bank = "กำลังโหลดข้อมูล";
      const response = await getUserBank(state.user);
      state.bank = "";
      state.bankOptions = [];
      console.log(response);
      if (response.status === "success") {
        response.data.forEach((element) => {
          state.bankOptions.push({
            label: element.bankName + " " + element.accNumber,
            value: element.id,
          });
        });
        if (state.bankOptions.length === 1) {
          state.bank = state.bankOptions[0].value;
        }
      } else if (response.status === "http_error") {
        state.bank = "ไม่พบบัญชีธนาคารในระบบ";
        state.isException = true;
        state.exceptionMessage = response.message;
      } else {
        state.bank = "ไม่พบบัญชีธนาคารในระบบ";
        state.isException = true;
        state.exceptionMessage =
          response.data?.descHtml !== undefined
            ? response.data.descHtml
            : response.message;
      }
    };

    const handleClickSendWithdraw = async (validate) => {
      if (validate === false) {
        return;
      }
      const userById = state.userLists.find((obj) => obj.id === state.user);
      const data = {
        type: "400",
        user: userById.user,
        bank_acc: state.bank,
        amount: parseFloat(state.amount).toFixed(2),
        note: state.note,
      };
      handleCloseDialog();
      state.isLoading = true;
      const response = await transactions(data);
      state.isLoading = false;
      if (response.status === "success") {
        state.isWithdraw = false;
      } else if (response.status === "http_error") {
        state.isException = true;
        state.exceptionMessage = response.message;
      } else {
        state.isException = true;
        state.exceptionMessage =
          response.data?.descHtml !== undefined
            ? response.data.descHtml
            : response.message;
      }
    };

    const handleCloseExceptionDialog = () => {
      state.isException = false;
    };

    return {
      ...toRefs(state),
      handleClickSendWithdraw,
      handleCloseDialog,
      handleUserChange,
      handleCloseExceptionDialog,
      numberRule,
      dateRule,
      selectRule,
    };
  },
};
</script>

<style lang="scss" scoped></style>
