import {
  getNewMessage,
  getChattingMessage,
  getWebs,
} from "@/services/leftsector-service";

const leftsectorModule = {
  namespaced: true,
  state: {
    newMessages: [],
    chattingMessages: [],
    searchMessages: [],
    unReadLength: 0,
    staffOnline: [],
    webs: [],
    web_id: null,
    viewMode: "all",
  },
  mutations: {
    RESET_STATE(state) {
      state.newMessages = [];
      state.chattingMessages = [];
      state.web_id = null;
    },

    SET_VIEWMODE_DATA(state, newViewModeData) {
      state.viewMode = newViewModeData;
    },

    SET_NEWMESSAGE_DATA(state, newMessagesData) {
      state.newMessages = newMessagesData;
    },

    SET_WEBS_DATA(state, newWebData) {
      newWebData.unshift({ id: 0, name: "ทั้งหมด" });
      state.webs = newWebData;
    },

    SET_WEB_ID_DATA(state, newWebIdData) {
      state.web_id = parseInt(newWebIdData);
    },

    SET_CHATTINGMESSAGE_DATA(state, newChattingMessagesData) {
      state.chattingMessages = newChattingMessagesData;
    },

    SET_SEARCHMESSAGE_DATA(state, newsearchMessagesData) {
      state.searchMessages = newsearchMessagesData;
    },

    SET_UNREADLENGTH_DATA(state, newUnReadLengthData) {
      state.unReadLength = newUnReadLengthData;
    },

    SET_STAFFONLINE_DATA(state, newStaffOnlineData) {
      state.staffOnline = newStaffOnlineData;
    },
  },
  actions: {
    async getNewMessages(context, reqData) {
      var data = `?staff_id=${reqData.staff_id}&is_read=0`;
      if (
        reqData.web_id !== null &&
        reqData.web_id !== "undefined" &&
        parseInt(reqData.web_id) !== 0
      ) {
        data += `&web_id=${reqData.web_id}`;
        context.commit("SET_WEB_ID_DATA", reqData.web_id);
      } else {
        localStorage.removeItem("web");
      }
      const response = await getNewMessage(data);
      const sorted =
        response.data !== null
          ? response.data.sort(
              (a, b) => a.first_unread_time - b.first_unread_time
            )
          : [];
      console.log("Set New Messages", sorted);
      context.commit("SET_NEWMESSAGE_DATA", sorted);
    },

    async getChattingMessages(context, reqData) {
      var data = ``;
      if (
        reqData.web_id !== null &&
        reqData.web_id !== "undefined" &&
        parseInt(reqData.web_id) !== 0
      ) {
        data += `?web_id=${reqData.web_id}`;
        context.commit("SET_WEB_ID_DATA", reqData.web_id);
      } else {
        localStorage.removeItem("web");
      }
      const response = await getChattingMessage(data);
      console.log("Set Chatting Messages", response);
      let unReadLength = 0;
      response.data.forEach((element) => {
        if (element.is_read === false && element.staff_id !== null) {
          unReadLength++;
        }
      });
      const sorted =
        response.data !== null
          ? response.data.sort((a, b) => {
              if (a.is_pin !== b.is_pin) {
                return a.is_pin ? -1 : 1;
              }
              if (a.is_read !== b.is_read) {
                return a.is_read ? 1 : -1;
              }
              return b.last_message_time - a.last_message_time;
            })
          : [];
      context.commit("SET_UNREADLENGTH_DATA", unReadLength);
      context.commit("SET_CHATTINGMESSAGE_DATA", sorted);
    },

    async getEndChattingMessages(context, chattingMessages) {
      const data = "?staff_id=&last_staff=1";
      const response = await getChattingMessage(data);
      console.log("Set EndChatting Messages", response);
      response.data.forEach((element) => {
        chattingMessages.push(element);
      });

      const sorted = chattingMessages.sort((a, b) => {
        if (a.is_pin !== b.is_pin) {
          return a.is_pin ? -1 : 1;
        }
        if (a.is_read !== b.is_read) {
          return a.is_read ? 1 : -1;
        }
        return b.last_message_time - a.last_message_time;
      });
      context.commit("SET_CHATTINGMESSAGE_DATA", sorted);
    },

    async getWebs(context) {
      const response = await getWebs();
      console.log("Set Webs", response.data);
      context.commit("SET_WEBS_DATA", response.data);
    },

    setNewMessages(context, data) {
      const sorted = data.sort(
        (a, b) => a.first_unread_time - b.first_unread_time
      );
      context.commit("SET_NEWMESSAGE_DATA", sorted);
    },

    setChattingMessages(context, data) {
      let unReadLength = 0;
      data.forEach((element) => {
        if (element.is_read === false && element.staff_id !== null) {
          unReadLength++;
        }
      });
      const sorted = data.sort((a, b) => {
        if (a.is_pin !== b.is_pin) {
          return a.is_pin ? -1 : 1;
        }
        if (a.is_read !== b.is_read) {
          return a.is_read ? 1 : -1;
        }
        return b.last_message_time - a.last_message_time;
      });
      context.commit("SET_UNREADLENGTH_DATA", unReadLength);
      context.commit("SET_CHATTINGMESSAGE_DATA", sorted);
    },
  },
};

export default leftsectorModule;
