import { computed, onMounted, reactive } from "vue";
import { utilily } from "@/utilities/utility";
import { Login, verifyOtp } from "@/services/leftsector-service";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export function useLogin() {
  const { setHeight } = utilily();

  const router = useRouter();
  const store = useStore();

  const state = reactive({
    username: "",
    password: "",
    isShowPwd: false,
    remember: false,
    isDialog: false,
    staff: computed(() => store.state.rightsectorModule.staff),
    isOtp: false,
    otpLength: 6,
    digits: Array(6).fill(""),
    otpCount: 0,
    otpRefCode: "",
    otpToken: "",
  });

  onMounted(() => {
    localStorage.removeItem("token");
  });

  const onSubmit = async () => {
    const data = { username: state.username, password: state.password };
    const response = await Login(data);
    if (response.status === "success") {
      if (response.type === "SET_LOGIN") {
        setLogin(response.access_token);
      } else {
        clearInterval(window.otpIntv);
        state.otpRefCode = response.ref_code;
        state.otpToken = response.otp_token;
        state.isOtp = true;
        state.otpCount = 60;
        window.otpIntv = setInterval(() => {
          state.otpCount--;
          if (state.otpCount <= 0) {
            clearInterval(window.otpIntv);
            state.digits = Array(6).fill("");
            state.username = "";
            state.password = "";
            state.isOtp = false;
          }
        }, 1000);
      }
    } else {
      state.isDialog = true;
    }
  };

  const onInput = (event, index) => {
    const value = event.target.value;

    if (!/^\d*$/.test(value)) {
      state.digits[index] = value.replace(/[^\d]/g, ""); // Remove non-numeric characters
      return;
    }

    if (state.digits[index].length > 1) {
      state.digits[index] = state.digits[index].charAt(0);
    }

    // Move to the next input field if the current field is filled
    if (value.length === 1 && index < state.otpLength - 1) {
      setTimeout(() => {
        document.querySelectorAll(".otp-field")[index + 1].focus();
      }, 0);
    }
    // Handle backspace to move to the previous field
    if (value.length === 0 && index > 0 && event.key === "Backspace") {
      setTimeout(() => {
        document.querySelectorAll(".otp-field")[index - 1].focus();
      }, 0);
    }
    // Check if all fields are filled and call submit method
    if (state.digits.every((digit) => digit.length === 1)) {
      submitOtp();
    }
  };

  const submitOtp = async () => {
    const otpValue = state.digits.join("");
    const data = {
      otp_token: state.otpToken,
      ref_code: state.otpRefCode,
      otp: otpValue,
    };
    const response = await verifyOtp(data);
    if (response.status === "success") {
      setLogin(response.access_token);
    } else {
      state.isDialog = true;
      state.digits = Array(6).fill("");
      document.querySelectorAll(".otp-field")[0].focus();
    }
  };

  const setLogin = (token) => {
    localStorage.setItem("token", token);
    router.replace({ name: "homePage" });
  };
  return { state, setHeight, onSubmit, onInput };
}
