import { updateRoom } from "@/services/centersector-service";
import { setRoomWork } from "@/services/leftsector-service";
import store from "@/store/index";

export function utilily() {
  const updateRooms = async (id, event) => {
    const data = `${id}?${event}`;
    const response = await updateRoom(data);
    console.log("Update Room", response);
  };

  const optionsDate = (date) => {
    return date <= new Date().toISOString().substr(0, 10).replace(/-/g, "/");
  };

  const numberWithCommas = (number = null) => {
    if (!number) {
      return 0;
    }
    if (typeof number === "string") {
      number = parseFloat(number);
    }

    if (Number.isInteger(number)) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else if (number % 1 === 0) {
      return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const isEmpty = (obj) => {
    for (var key in obj) {
      if (obj[key] !== undefined) {
        return false;
      }
    }
    return true;
  };

  const setHeight = (offset) => {
    const height = offset ? `calc(100vh - ${offset}px)` : "100vh";
    document.documentElement.style.setProperty("--height", height);
    return { height };
  };

  const searchObjectById = (array, id) => {
    return array.find((obj) => obj.id === id);
  };

  const getWork = async (id) => {
    const data = `${id}?work=1`;
    const response = await setRoomWork(data);
    console.log("get work", response);
  };

  const getChat = async (id, web_id, staff_id, is_read = true) => {
    if (staff_id === null) {
      await store.dispatch("centersectorModule/setPreview", true);
    } else {
      await store.dispatch("centersectorModule/setPreview", false);
    }
    await store.dispatch("rightsectorModule/getCustomer", id);
    store.commit("centersectorModule/SET_LOADINGCHAT_DATA", true);
    await store.dispatch("centersectorModule/getRoom", {
      id: id,
      web_id: web_id,
    });

    const element = document.querySelector(
      "#scroll-sector .q-scrollarea__container"
    );
    element.scrollTop = element.scrollHeight;
    store.commit("centersectorModule/SET_LOADINGCHAT_DATA", false);

    if (is_read === false) {
      updateRooms(id, "is_read=1");
    }

    // await store.dispatch("rightsectorModule/getDepositLists", id);
    // await store.dispatch("rightsectorModule/getWithdrawLists", id);
    await store.dispatch("rightsectorModule/getUserLists", id);
  };

  const padNumber = (num) => {
    return num.toString().padStart(2, "0");
  };

  const dateFormat = (timestamp) => {
    const today = new Date();
    const targetDate = new Date(timestamp * 1000);
    const weekdays = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."];
    const months = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];

    const dayOfWeek = weekdays[targetDate.getDay()];
    const day = targetDate.getDate();
    const month = months[targetDate.getMonth()];

    if (isSameDay(today, targetDate)) {
      return "วันนี้";
    } else if (isYesterday(today, targetDate)) {
      return "เมื่อวาน";
    } else {
      return `${dayOfWeek} ${day} ${month}`;
    }
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const isYesterday = (date1, date2) => {
    const yesterday = new Date(date1);
    yesterday.setDate(date1.getDate() - 1);

    return isSameDay(yesterday, date2);
  };

  const numberRule = (value) => {
    if (!value) {
      return "กรุณากรอกจำนวนเงิน";
    }

    const regex = /^\d+(\.\d{1,2})?$/;

    if (!regex.test(value)) {
      return "กรุณากรอกจำนวนเงินให้ถูกต้อง";
    }

    return true;
  };

  const dateRule = (value) => {
    if (!value) {
      return "กรุณากรอกวันที่";
    }

    const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;

    if (!regex.test(value)) {
      return "กรุณากรอกวันที่และเวลาให้ถูกต้อง";
    }

    return true;
  };

  const selectRule = (value) => {
    if (
      typeof value !== "object" &&
      value !== "" &&
      value !== "กำลังโหลดข้อมูล" &&
      value !== "ไม่พบบัญชีธนาคารในระบบ"
    ) {
      return true;
    } else {
      return "";
    }
  };

  return {
    numberWithCommas,
    optionsDate,
    updateRooms,
    isEmpty,
    setHeight,
    searchObjectById,
    getWork,
    getChat,
    padNumber,
    dateFormat,
    numberRule,
    dateRule,
    selectRule,
  };
}
