import { request } from "./api";

export function getCustomer(data) {
  const url = `/api/chatbot/customers/${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function getStaff() {
  const url = `/api/chatbot/me`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function getUser(data) {
  const url = `/api/chatbot/customerusers/${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function refreshCustomer(data) {
  const url = `/api/chatbot/refreshcustomer/${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function putUser(data) {
  const url = `/api/chatbot/customers/${data.id}`;
  return request("put", url, data.data, localStorage.getItem("token"));
}

// export function getBankLists(data) {
//   const url = `/api/chatbot/depositbank/${data}`;
//   return request("get", url, {}, localStorage.getItem("token"));
// }

export function getBankLists(data) {
  const url = `/api/chatbot/depositdata/${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function getBank(data) {
  const url = `/api/chatbot/depositbank/${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function getUserBank(data) {
  const url = `/api/chatbot/listmemberbank/${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function getUserBankInfo(data) {
  const url = `/api/chatbot/memberbankinfo/${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function transactions(data) {
  const url = `/api/chatbot/transactions`;
  return request("post", url, data, localStorage.getItem("token"));
}

export function getTransactions(data) {
  const url = `/api/chatbot/transactions${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}
