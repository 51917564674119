<template>
  <q-dialog v-model="isWithdrawDetail" persistent>
    <q-card class="financialDialog" style="width: 300px; max-width: 30vw">
      <q-card-section
        class="row justify-center items-center header red font-semibold q-pa-sm"
      >
        <div class="text-h6">ID : {{ withdrawDetail.code }}</div>
        <q-btn
          @click="handleCloseDialog"
          class="absolute-right"
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>

      <q-card-section class="row q-col-gutter-x-md">
        <div class="col q-gutter-y-sm font-regular">
          <div>
            <span class="text-greys">User : </span>
            <span>{{ withdrawDetail.more_data.user }}</span>
          </div>
          <div>
            <span class="text-greys">บัญชี : </span>
            <span>{{ withdrawDetail.more_data.bank_acc }}</span>
          </div>
          <div>
            <span class="text-greys">ยอดเงิน : </span>
            <span>{{ numberWithCommas(withdrawDetail.amount) }} บาท</span>
          </div>
          <div>
            <span class="text-greys">เวลา : </span>
            <span>{{ withdrawDetail.updated_at }}</span>
          </div>
          <div>
            <span class="text-greys">สถานะ : </span>
            <q-badge
              :color="
                withdrawDetail.status >= 900
                  ? 'normal-green'
                  : withdrawDetail.status >= 300
                  ? 'light-red'
                  : withdrawDetail.status >= 100
                  ? 'dark-orange'
                  : 'light-red'
              "
              class="q-py-xs q-px-sm justify-center"
            >
              {{
                withdrawDetail.status >= 900
                  ? "เรียบร้อย"
                  : withdrawDetail.status >= 300
                  ? "ยกเลิก"
                  : withdrawDetail.status >= 100
                  ? "รอดำเนินการ"
                  : "Unknown"
              }}
            </q-badge>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, reactive, toRefs, watch } from "vue";
import { getUserBankInfo } from "@/services/rightsector-service";
import { utilily } from "@/utilities/utility";
import { useStore } from "vuex";

export default {
  props: ["isWithdrawDetails", "withdrawDetailData"],
  emits: ["handleCloseDialog"],
  setup(props, { emit }) {
    const { padNumber, numberWithCommas } = utilily();

    const store = useStore();

    const state = reactive({
      isWithdrawDetail: false,
      withdrawDetail: {},
      userLists: computed(() => store.state.rightsectorModule.userLists),
    });

    watch(
      () => props.isWithdrawDetails,
      (isWithdrawDetail) => {
        state.isWithdrawDetail = isWithdrawDetail;
        if (isWithdrawDetail === true) {
          state.withdrawDetail = props.withdrawDetailData;
          initData();
        }
      }
    );

    const initData = async () => {
      const userByName = state.userLists.find(
        (obj) => obj.user === state.withdrawDetail.more_data.user
      );
      if (userByName) {
        const req = `${userByName.id}/${state.withdrawDetail.more_data.bank_acc}`;
        const response = await getUserBankInfo(req);
        if (response.status === "success" && response.data !== null) {
          state.withdrawDetail.more_data.bank_acc =
            response.data.bankName + " " + response.data.accNumber;
        }
      } else {
        state.withdrawDetail.more_data.bank_acc = `[ไม่พบบัญชีธนาคาร]`;
      }

      const date = new Date(state.withdrawDetail.updated_at * 1000);

      // Format the date string as desired
      state.withdrawDetail.updated_at = `${date.getFullYear()}-${padNumber(
        date.getMonth() + 1
      )}-${padNumber(date.getDate())} ${padNumber(date.getHours())}:${padNumber(
        date.getMinutes()
      )}`;
    };

    const handleCloseDialog = () => {
      emit("handleCloseDialog");
    };

    return {
      ...toRefs(state),
      handleCloseDialog,
      numberWithCommas,
    };
  },
};
</script>

<style lang="scss" scoped></style>
