<template>
  <q-dialog v-model="isDialog" @hide="handleCloseDialog">
    <q-card class="exception-dialog font-regular">
      <div class="q-pa-md" v-html="exceptionMessage"></div>
    </q-card>
  </q-dialog>
</template>

<script>
import { reactive, toRefs, watch } from "vue";

export default {
  props: ["isException", "exceptionMessage"],
  emits: ["handleCloseDialog"],
  setup(props, { emit }) {
    const state = reactive({
      isDialog: false,
    });

    watch(
      () => props.isException,
      (isException) => {
        state.isDialog = isException;
      }
    );

    const handleCloseDialog = () => {
      emit("handleCloseDialog");
    };

    return {
      ...toRefs(state),
      handleCloseDialog,
    };
  },
};
</script>

<style lang="scss" scoped></style>
