import { getRoom, getImage } from "@/services/centersector-service";
import { utilily } from "../../utilities/utility";

const centersectorModule = {
  namespaced: true,
  state: {
    chatBoxs: [],
    firstChat: [],
    isBtn: false,
    isPreview: false,
    isOnline: false,
    currentDate: null,
    isLastMessage: false,
    loadingChat: false,
    staffLists: [],
    chatStaff: "",
  },
  mutations: {
    SET_LOADINGCHAT_DATA(state, newLoadingChat) {
      state.loadingChat = newLoadingChat;
    },

    SET_CHATSTAFF_DATA(state, newStaffName) {
      state.chatStaff = newStaffName;
    },

    SET_STAFFLISTS_DATA(state, newStaffData) {
      state.staffLists = newStaffData;
    },

    SET_CHATBOX_DATA(state, newChatBoxData) {
      const { dateFormat } = utilily();
      state.currentDate = null;
      state.isLastMessage = false;
      newChatBoxData.chat.forEach(async (element, index) => {
        if (
          element.message.type === "image" &&
          !element?.message?.originalContentUrl
        ) {
          getImage(`?web_id=${newChatBoxData.web_id}&message_id=${element.id}`);
        }
        const chatDate = dateFormat(element.created_at);
        if (chatDate !== state.currentDate) {
          newChatBoxData.chat[index].chatDate = chatDate;
        } else {
          newChatBoxData.chat[index].chatDate = null;
        }
        state.currentDate = chatDate;
      });
      state.chatBoxs = newChatBoxData.chat;
    },

    SET_FIRSTCHAT_DATA(state, newFirstChatData) {
      const { dateFormat } = utilily();
      state.currentDate = null;
      state.isLastMessage = false;
      newFirstChatData.forEach((element, index) => {
        const chatDate = dateFormat(element.created_at);
        if (chatDate !== state.currentDate) {
          newFirstChatData[index].chatDate = chatDate;
        } else {
          newFirstChatData[index].chatDate = null;
        }
        state.currentDate = chatDate;
      });
      state.firstChat = newFirstChatData;
    },

    MERGED_CHATBOX_DATA(state, newChatBoxData) {
      if (newChatBoxData.chat.length <= 0) {
        console.log("isLast");
        state.isLastMessage = true;
        return;
      }
      const mergedArray = [...newChatBoxData.chat, ...state.chatBoxs];
      const { dateFormat } = utilily();
      state.currentDate = null;
      mergedArray.forEach((element, index) => {
        if (
          element.message.type === "image" &&
          !element?.message?.originalContentUrl
        ) {
          getImage(`?web_id=${newChatBoxData.web_id}&message_id=${element.id}`);
        }
        const chatDate = dateFormat(element.created_at);
        if (chatDate !== state.currentDate) {
          mergedArray[index].chatDate = chatDate;
        } else {
          mergedArray[index].chatDate = null;
        }
        state.currentDate = chatDate;
      });
      state.chatBoxs = mergedArray;
    },

    SET_PREVIEW_DATA(state, newIsPreviewData) {
      state.isPreview = newIsPreviewData;
    },

    SET_ISBTN_DATA(state, newIsBtnData) {
      state.isBtn = newIsBtnData;
    },

    SET_ISONLINE_DATA(state, newIsOnlineData) {
      state.isOnline = newIsOnlineData;
    },
  },
  actions: {
    async getRoom(context, chat) {
      const data = `${chat.id}`;
      const response = await getRoom(data);
      console.log("GET CHAT", response.data);
      context.commit("SET_CHATBOX_DATA", {
        chat: response.data,
        web_id: chat.web_id,
      });
    },

    async getFirstChat(context, chat) {
      const data = `${chat.id}`;
      const response = await getRoom(data);
      console.log("GET FIRSTCHAT", response.data);
      context.commit("SET_FIRSTCHAT_DATA", response.data);
    },

    async getOldestChat(context, chat) {
      const data = `${chat.id}?skip=${chat.chat_id}`;
      const response = await getRoom(data);
      console.log("GET Oldest CHAT", response.data);
      context.commit("MERGED_CHATBOX_DATA", {
        chat: response.data,
        web_id: chat.web_id,
      });
    },

    setIsBtn(context, isBtn) {
      context.commit("SET_ISBTN_DATA", isBtn);
    },

    setPreview(context, isPreview) {
      context.commit("SET_PREVIEW_DATA", isPreview);
    },

    setChatBoxs(context, response) {
      context.commit("SET_CHATBOX_DATA", {
        chat: response.data,
        web_id: response.web_id,
      });
    },
  },
};

export default centersectorModule;
