<template>
  <q-layout view="hHh lpR fFf" class="bg">
    <headerComponent page="login" />

    <q-page-container>
      <q-page :style-fn="setHeight">
        <div class="row flex justify-center items-center full-height">
          <!-- LOGIN SECTOR -->
          <q-card v-if="!isOtp" class="login-card">
            <q-card-section>
              <div class="text-h6 text-center font-medium">ลงชื่อเข้าใช้</div>
              <q-form class="q-gutter-md q-mt-none font-regular">
                <q-input
                  color="grey"
                  filled
                  v-model="username"
                  label="ชื่อผู้ใช้"
                >
                  <template v-slot:prepend>
                    <q-icon name="person" />
                  </template>
                </q-input>
                <q-input
                  :type="isShowPwd === false ? 'password' : 'text'"
                  color="grey"
                  filled
                  v-model="password"
                  label="รหัสผ่าน"
                >
                  <template v-slot:prepend>
                    <q-icon name="lock" />
                  </template>
                  <template v-slot:append>
                    <q-icon
                      :name="
                        isShowPwd === true ? 'visibility' : 'visibility_off'
                      "
                      class="cursor-pointer"
                      color="grey-8f"
                      @click="isShowPwd = !isShowPwd"
                    />
                  </template>
                </q-input>

                <div class="flex justify-end items-center q-mt-none">
                  <q-checkbox
                    v-model="remember"
                    class="text-small"
                    color="grey"
                    label="จำบัญชีนี้"
                  />
                </div>
                <div>
                  <q-btn
                    @click="onSubmit"
                    color="gd-green"
                    text-color="white"
                    class="full-width font-bolds round-radius"
                    label="เข้าสู่ระบบ"
                    unelevated
                  />
                </div>
              </q-form>
            </q-card-section>
          </q-card>
          <!-- OTP SECTOR -->
          <q-card v-else class="login-card">
            <q-card-section>
              <div class="text-h6 text-center font-medium">กรอก OTP</div>
              <q-form class="q-gutter-md q-mt-none font-regular otp-input">
                <q-input
                  v-for="(digit, index) in otpLength"
                  :key="index"
                  v-model="digits[index]"
                  type="text"
                  @keyup="onInput($event, index)"
                  class="otp-field"
                  input-class="text-center"
                  standout="bg-grey"
                  style="width: 50px; margin-right: 5px"
                />
              </q-form>
              <div class="flex justify-between items-center q-mt-md">
                <span>Ref code: {{ otpRefCode }}</span>
                <span>{{ otpCount }} s</span>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <q-dialog
          v-model="isDialog"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-card class="font-regular rounded bg-white">
            <q-card-section class="flex items-center justify-center q-px-xl">
              <div v-if="!isOtp" class="font-medium">
                <div class="text-center" style="font-size: 1.5rem">
                  ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง
                </div>
                <div class="text-center" style="font-size: 1.1rem">
                  กรุณาลองใหม่อีกครั้งหรือติดต่อแอดมิน
                </div>
              </div>
              <div v-else class="font-medium">
                <div class="text-center" style="font-size: 1.5rem">
                  รหัส OTP ไม่ถูกต้อง
                </div>
                <div class="text-center" style="font-size: 1.1rem">
                  กรุณาลองใหม่อีกครั้งหรือติดต่อแอดมิน
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-dialog>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { toRefs } from "vue";
import headerComponent from "@/components/Header.vue";
import { useLogin } from "./composable/use-login";

export default {
  components: { headerComponent },
  setup() {
    const { state, setHeight, onSubmit, onInput } = useLogin();

    return {
      ...toRefs(state),
      setHeight,
      onSubmit,
      onInput,
    };
  },
};
</script>

<style lang="scss" scoped>
.text-grey-8f {
  color: $grey-8f;
}

.login-card {
  border-radius: 1rem;
  & button {
    border-radius: 0.5rem;
  }
}

.otp-input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-field {
  border-radius: 0.5rem;
  font-size: 2rem !important;
}
</style>
