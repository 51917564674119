<template>
  <q-page :style-fn="setHeight" style="min-width: 1366px">
    <div class="row q-col-gutter-md q-mt-none full-height">
      <Leftsector
        :newMessages="newMessages"
        :chattingMessages="chattingMessages"
        :searchMessages="searchMessages"
        :customer="customer"
        :staff="staff"
        :web_id="web_id"
      />
      <CenterSector
        :chatBoxs="chatBoxs"
        :customer="customer"
        :isPreview="isPreview"
        :isLastMessage="isLastMessage"
        :chattingMessages="chattingMessages"
        :searchMessages="searchMessages"
        :staff="staff"
        :staffLists="staffLists"
        :bankMessage="bankMessage"
        :chatStaff="chatStaff"
        :viewMode="viewMode"
        :loadingChat="loadingChat"
      />
      <RightSector
        :customer="customer"
        :depositLists="depositLists"
        :withdrawLists="withdrawLists"
        :userLists="userLists"
        :isNormalDeposit="isNormalDeposit"
      />
    </div>
    <audio id="received_chat" style="display: none">
      <source src="@/assets/received_chat.mp3" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
    <audio id="unreceived_chat" style="display: none">
      <source src="@/assets/unreceived_chat2.mp3" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
    <q-dialog
      v-model="isDialogWss"
      persistent
      transition-show="scale"
      transition-hide="scale"
    >
      <q-card class="font-regular rounded bg-white">
        <q-card-section class="flex items-center justify-center q-px-xl">
          <div class="font-medium">
            <div style="font-size: 1.5rem">ไม่สามารถเชื่อมต่อระบบได้</div>
            <div class="loading" style="font-size: 1.1rem">
              กรุณารอสักครู่ กำลังเชื่อมต่อใหม่
              <span>.</span>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
            <div class="text-center q-mt-sm">
              <q-btn
                @click="(isDialogWss = false), (isOfflineMode = true)"
                color="red"
                text-color="white"
                label="ใช้งานแบบออฟไลน์"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="isDialogRefresh"
      persistent
      transition-show="scale"
      transition-hide="scale"
    >
      <q-card class="font-regular rounded bg-white">
        <q-card-section class="flex items-center justify-center q-px-xl">
          <div class="font-medium">
            <div style="font-size: 1.5rem">
              ไม่สามารถเชื่อมต่อกับ Server ได้
            </div>
            <div class="text-center q-mt-sm">
              <q-btn
                @click="handleClickRefresh"
                color="red"
                text-color="white"
                label="Refresh"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import Leftsector from "@/components/LeftSectors.vue";
import CenterSector from "@/components/CenterSectors.vue";
import RightSector from "@/components/RightSectors.vue";
import { useHome } from "./composable/use-home";

export default {
  components: { Leftsector, CenterSector, RightSector },
  setup() {
    const { state, setHeight, handleClickRefresh } = useHome();

    return {
      ...toRefs(state),
      setHeight,
      handleClickRefresh,
    };
  },
};
</script>

<style lang="scss" scoped>
.loading {
  span {
    animation: dot 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
  }
}
</style>
