import { computed, onBeforeUnmount, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import { io } from "socket.io-client";
import { utilily } from "@/utilities/utility";
import { getCustomer } from "@/services/rightsector-service";
import { useRouter } from "vue-router";
import { getStaffOnline } from "../../../services/centersector-service";

export function useHome() {
  const store = useStore();
  const router = useRouter();

  const { setHeight, searchObjectById, updateRooms } = utilily();

  const state = reactive({
    newMessages: computed(() => store.state.leftsectorModule.newMessages),
    chattingMessages: computed(
      () => store.state.leftsectorModule.chattingMessages
    ),
    searchMessages: computed(() => store.state.leftsectorModule.searchMessages),
    webs: computed(() => store.state.leftsectorModule.webs),
    viewMode: computed(() => store.state.leftsectorModule.viewMode),
    chatBoxs: computed(() => store.state.centersectorModule.chatBoxs),
    firstChat: computed(() => store.state.centersectorModule.firstChat),
    loadingChat: computed(() => store.state.centersectorModule.loadingChat),
    customer: computed(() => store.state.rightsectorModule.customer),
    staff: computed(() => store.state.rightsectorModule.staff),
    staffLists: computed(() => store.state.centersectorModule.staffLists),
    isLastMessage: computed(() => store.state.centersectorModule.isLastMessage),
    isPreview: computed(() => store.state.centersectorModule.isPreview),
    isBtn: computed(() => store.state.centersectorModule.isBtn),
    chatStaff: computed(() => store.state.centersectorModule.chatStaff),
    depositLists: computed(() => store.state.rightsectorModule.depositLists),
    withdrawLists: computed(() => store.state.rightsectorModule.withdrawLists),
    userLists: computed(() => store.state.rightsectorModule.userLists),
    bankMessage: computed(() => store.state.rightsectorModule.bankMessage),
    isNormalDeposit: computed(
      () => store.state.rightsectorModule.isNormalDeposit
    ),
    web_id: computed(() => store.state.leftsectorModule.web_id),
    isDialogWss: false,
    isDialogRefresh: false,
    isOfflineMode: false,
    newMessageBackup: {},
    webSocket: "",
    wssReconnectAttempt: 0,
  });

  onBeforeUnmount(() => {
    state.webSocket.disconnect();

    resetState();
  });

  onMounted(async () => {
    await initWebSocket();

    setTimeout(() => {
      getStaffOnlineData();
    }, 1000);

    window.chattimeIntv = setInterval(() => {
      const currentTime = new Date().getTime();

      state.chattingMessages.forEach((element) => {
        const timeDifference = currentTime - element.last_message_time * 1000;
        const chat = document.querySelector("#chatting" + element.id);
        if (chat !== null) {
          if (timeDifference > 300000) {
            if (!chat.classList.contains("flashing-color-red")) {
              chat.classList.add("flashing-color-red");
              // console.log(
              //   `Object with ID ${element.id} has a timestamp more than 5 minutes old.`
              // );
            }
          } else if (timeDifference > 120000) {
            if (!chat.classList.contains("flashing-color-yellow")) {
              chat.classList.add("flashing-color-yellow");
              // console.log(
              //   `Object with ID ${element.id} has a timestamp more than 3 minutes old.`
              // );
            }
          }
        }
      });
    }, 5000);

    window.staffIntv = setInterval(() => {
      getStaffOnlineData();
    }, 15000);

    await store.dispatch("leftsectorModule/getWebs");
    store.commit(
      "leftsectorModule/SET_WEB_ID_DATA",
      localStorage.getItem("web") !== null ? localStorage.getItem("web") : 0
    );
  });

  watch(
    () => state.web_id,
    () => {
      store.commit("rightsectorModule/SET_CUSTOMER_DATA", {});
      initData();
    }
  );

  const initData = async () => {
    const webId = localStorage.getItem("web");
    store.dispatch("rightsectorModule/getStaff");
    await store.dispatch("leftsectorModule/getNewMessages", {
      staff_id: "",
      web_id: webId,
    });
    await store.dispatch("leftsectorModule/getChattingMessages", {
      web_id: webId,
    });
  };

  const getStaffOnlineData = async () => {
    const response = await getStaffOnline();
    console.log("GET STAFF ONLINE", response);
    if (response.status === "success") {
      state.staffOnlines = response.data;
      store.commit("leftsectorModule/SET_STAFFONLINE_DATA", response.data);
    }
  };

  const resetState = () => {
    store.commit("rightsectorModule/RESET_STATE");
    store.commit("leftsectorModule/RESET_STATE");
  };

  const handleClickRefresh = () => {
    router.go(0);
  };

  const initWebSocket = async () => {
    const wssURL = process.env.VUE_APP_WSS_URL
      ? process.env.VUE_APP_WSS_URL
      : "https://wss.chatbot.softbot.space";
    state.webSocket = io(wssURL, {
      // transports: ["websocket"],
      autoConnect: true,
      // reconnectionAttempts: 4,
      transports: ["websocket"],
      withCredentials: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      query: {
        t: localStorage.getItem("token"),
      },
    });

    state.webSocket.on("connect", () => {
      state.isDialogWss = false;
      state.isOfflineMode = false;
      store.commit("centersectorModule/SET_ISONLINE_DATA", true);
      state.wssReconnectAttempt = 0;
      console.log(`wss connected with the id of ${state.webSocket.id}`); // x8WIv7-mJelg7on_ALbx
    });

    state.webSocket.io.on("reconnect", () => {
      state.isDialogWss = false;
      state.isOfflineMode = false;
      store.commit("centersectorModule/SET_ISONLINE_DATA", true);
      resetState();
      initData();
      console.log("RECONNECTED");
    });

    state.webSocket.io.on("reconnect_attempt", () => {
      if (state.isOfflineMode === false) {
        state.isDialogWss = true;
      }
      state.wssReconnectAttempt++;
      console.log("RECONNECT ATTEMPT", state.wssReconnectAttempt);
      if (state.wssReconnectAttempt >= 5) {
        state.webSocket.disconnect();
        state.isDialogWss = false;
        store.commit("centersectorModule/SET_ISONLINE_DATA", false);
        state.isDialogRefresh = true;
      }
    });

    state.webSocket.on("disconnect", () => {
      console.log(`wss disconnected`);
    });

    state.webSocket.on("msg", (data) => {
      if (data.event === "room") {
        handleWssRoom(data.data);
      } else if (data.event === "line_message") {
        handleWssMessage(data.data);
      } else if (data.event === "logout") {
        handleWssLogout(data.data);
      } else if (data.event === "customer") {
        handleWssCustomer(data.data);
      } else if (data.event === "transaction") {
        handleWssTransaction(data.data);
      }
    });
  };

  const handleWssTransaction = (data) => {
    console.log("Transaction", data);
    if (data.type === "200") {
      const foundObject = state.depositLists.findIndex(
        (obj) => obj.code === data.code
      );
      if (foundObject !== -1) {
        let clonedArray = [...state.depositLists];
        clonedArray[foundObject] = data;
        store.commit("rightsectorModule/SET_DEPOSITLISTS_DATA", clonedArray);
      } else {
        store.commit("rightsectorModule/MERGED_DEPOSITLIST_DATA", data);
      }
    } else {
      const foundObject = state.withdrawLists.findIndex(
        (obj) => obj.code === data.code
      );
      if (foundObject !== -1) {
        let clonedArray = [...state.withdrawLists];
        clonedArray[foundObject] = data;
        store.commit("rightsectorModule/SET_WITHDRAWLISTS_DATA", clonedArray);
      } else {
        store.commit("rightsectorModule/MERGED_WITHDRAWLIST_DATA", data);
      }
    }
  };

  const handleWssCustomer = (data) => {
    console.log("Customer", data);
    if (state.customer.id === data.id) {
      store.dispatch("rightsectorModule/setCustomer", data);
    }
  };

  const handleWssLogout = (data) => {
    if (
      data.staff_id === state.staff.id &&
      window.location.pathname !== "/login"
    ) {
      router.replace({ name: "login" });
    }
  };

  const handleWssRoom = (data) => {
    console.log("ROOM", data);
    const newMessageSearch = searchObjectById(state.newMessages, data.id);
    const chattingMessagesSearch = searchObjectById(
      state.chattingMessages,
      data.id
    );
    const searchMessagesSearch = searchObjectById(
      state.searchMessages,
      data.id
    );
    if (searchMessagesSearch !== undefined) {
      handleWssSearchMessages(data);
    }
    if (
      newMessageSearch === undefined &&
      (chattingMessagesSearch === undefined ||
        chattingMessagesSearch.staff_id !== state.staff.id) &&
      (searchMessagesSearch === undefined || data.staff_id === null)
    ) {
      handleFirstMessage(data);
    } else if (newMessageSearch !== undefined) {
      handleWssNewMessages(newMessageSearch, data);
    } else if (chattingMessagesSearch !== undefined) {
      handleWssChattingMessages(data);
    } else if (searchMessagesSearch !== undefined) {
      handleWssNewMessages(searchMessagesSearch, data);
      // handleWssSearchMessages(data, true);
    }
  };

  const handleFirstMessage = async (data) => {
    if (
      parseInt(state.web_id) !== parseInt(data.web_id) &&
      parseInt(state.web_id) !== 0
    ) {
      return;
    }
    console.log("Pass 1");
    if (data.staff_id === null && data.is_read === false) {
      const response = await getCustomer(data.customer_id);
      if (response.status === "success") {
        const unreceived_chat = document.getElementById("unreceived_chat");
        unreceived_chat.play();
        const customerData = {
          customer: response.data,
          last_message: JSON.parse(JSON.stringify(state.newMessageBackup)),
        };
        const mergedCustomerObject = Object.assign({}, data, customerData);

        if (!data.last_message) {
          await store.dispatch("centersectorModule/getFirstChat", {
            id: data.id,
            web_id: data.web_id,
          });
          const last_messageData = {
            last_message: state.firstChat[state.firstChat.length - 1],
          };
          var mergedMessageObject = Object.assign(
            {},
            mergedCustomerObject,
            last_messageData
          );
        }

        let newMessagesArray = JSON.parse(JSON.stringify(state.newMessages));
        newMessagesArray.push(mergedMessageObject);

        store.dispatch("leftsectorModule/setNewMessages", newMessagesArray);
      }
    }
  };

  const handleWssNewMessages = (searched, data) => {
    console.log("Pass 2");
    searched.staff_id = data.staff_id;
    if (searched.staff_id === state.staff.id) {
      let chattingArray = JSON.parse(JSON.stringify(state.chattingMessages));
      let indexChat = chattingArray.findIndex((element) => {
        return element.id === searched.id;
      });
      if (indexChat === -1) {
        chattingArray.push(searched);
        store.dispatch("leftsectorModule/setChattingMessages", chattingArray);
      } else {
        chattingArray[indexChat].is_pin = data.is_pin;
        chattingArray[indexChat].is_read = data.is_read;
        chattingArray[indexChat].is_process = data.is_process;
        chattingArray[indexChat].last_message_time = data.last_message_time;
        chattingArray[indexChat].first_unread_time = data.first_unread_time;
        chattingArray[indexChat].last_staff_id = data.last_staff_id;
        chattingArray[indexChat].staff_id = data.staff_id;
        store.dispatch("leftsectorModule/setChattingMessages", chattingArray);
      }
    }
    if (searched.staff_id !== null) {
      let newMessagesArray = JSON.parse(JSON.stringify(state.newMessages));
      let indexNew = newMessagesArray.findIndex((element) => {
        return element.id === searched.id;
      });
      if (indexNew !== -1) {
        newMessagesArray.splice(indexNew, 1);
      }
      store.dispatch("leftsectorModule/setNewMessages", newMessagesArray);
    } else {
      let newMessagesArray = JSON.parse(JSON.stringify(state.newMessages));
      let indexNew = newMessagesArray.findIndex((element) => {
        return element.id === searched.id;
      });
      if (indexNew !== -1) {
        let newMessagesArray = JSON.parse(JSON.stringify(state.newMessages));
        newMessagesArray[indexNew].last_message = state.newMessageBackup;
        store.dispatch("leftsectorModule/setNewMessages", newMessagesArray);
      }
    }
  };

  const handleWssChattingMessages = (data) => {
    console.log("Pass 3");
    let chattingArray = JSON.parse(JSON.stringify(state.chattingMessages));
    let index = chattingArray.findIndex((element) => {
      return element.id === data.id;
    });

    if (data.staff_id !== null) {
      chattingArray[index].is_pin = data.is_pin;
      chattingArray[index].is_read = data.is_read;
      chattingArray[index].is_process = data.is_process;
      chattingArray[index].last_message_time = data.last_message_time;
      chattingArray[index].first_unread_time = data.first_unread_time;
      chattingArray[index].last_staff_id = data.last_staff_id;
      chattingArray[index].staff_id = data.staff_id;
      if (
        state.customer.id === data.customer_id &&
        state.isBtn === false &&
        chattingArray[index].is_read === false
      ) {
        chattingArray[index].is_read = true;
        updateRooms(chattingArray[index].id, "is_read=1");
      }
      if (data.staff_id !== state.staff.id) {
        store.dispatch("rightsectorModule/setCustomer", {});
        chattingArray.splice(index, 1);
      }
    } else {
      if (index !== -1) {
        if (data.is_read === false) {
          let newMessagesArray = JSON.parse(JSON.stringify(state.newMessages));
          newMessagesArray.push(chattingArray[index]);
          store.dispatch("leftsectorModule/setNewMessages", newMessagesArray);
        }
        store.dispatch("rightsectorModule/setCustomer", {});
        chattingArray.splice(index, 1);
        // chattingArray[index].is_pin = data.is_pin;
        // chattingArray[index].is_read = data.is_read;
        // chattingArray[index].is_process = data.is_process;
        // chattingArray[index].last_staff_id = data.last_staff_id;
        // chattingArray[index].staff_id = data.staff_id;
      }
    }
    store.dispatch("centersectorModule/setIsBtn", false);
    store.dispatch("leftsectorModule/setChattingMessages", chattingArray);
  };

  const handleWssSearchMessages = (data) => {
    console.log("Pass 4");
    let searchArray = JSON.parse(JSON.stringify(state.searchMessages));
    let index = searchArray.findIndex((element) => {
      return element.id === data.id;
    });

    searchArray[index].is_pin = data.is_pin;
    searchArray[index].is_read = data.is_read;
    searchArray[index].is_process = data.is_process;
    searchArray[index].last_message_time = data.last_message_time;
    searchArray[index].first_unread_time = data.first_unread_time;
    searchArray[index].last_staff_id = data.last_staff_id;
    searchArray[index].staff_id = data.staff_id;
    // store.dispatch("rightsectorModule/setCustomer", {});

    store.dispatch("centersectorModule/setIsBtn", false);
    store.commit("leftsectorModule/SET_SEARCHMESSAGE_DATA", searchArray);
  };

  const handleWssMessage = async (data) => {
    console.log("Message", data);
    if (data.staff_id === undefined) {
      data.staff_id = null;
    }
    if (state.customer.id === parseInt(data.room_id)) {
      let chatBoxsArray = JSON.parse(JSON.stringify(state.chatBoxs));
      let index = -1;

      // if (data.message.resend !== undefined) {
      index = chatBoxsArray.findIndex((element) => {
        return element.id === data.id;
      });
      // }
      console.log("index", data.message.type, data.id, index);
      if (index === -1) {
        chatBoxsArray.push(data);

        await store.dispatch("centersectorModule/setChatBoxs", {
          data: chatBoxsArray,
          web_id: state.customer.web.id,
        });
      } else {
        chatBoxsArray[index] = data;

        await store.dispatch("centersectorModule/setChatBoxs", {
          data: chatBoxsArray,
          web_id: state.customer.web.id,
        });
      }

      const element = document.querySelector(
        "#scroll-sector .q-scrollarea__container"
      );
      element.scrollTop = element.scrollHeight;
    }

    // let newMessagesArray = JSON.parse(JSON.stringify(state.newMessages));
    // let indexNew = newMessagesArray.findIndex((element) => {
    //   return element.id === parseInt(data.room_id);
    // });
    // if (indexNew !== -1) {
    //   const unreceived_chat = document.getElementById("unreceived_chat");
    //   unreceived_chat.play();
    // }

    let chattingArray = JSON.parse(JSON.stringify(state.chattingMessages));
    let indexChat = chattingArray.findIndex((element) => {
      return element.id === parseInt(data.room_id);
    });
    if (indexChat !== -1) {
      if (state.customer.id !== parseInt(data.room_id)) {
        const received_chat = document.getElementById("received_chat");
        received_chat.play();
      }

      chattingArray[indexChat].last_message.message = data.message;
      chattingArray[indexChat].last_message.staff_id = data.staff_id;

      if (data.is_bot === undefined) {
        if (data.staff_id === null) {
          chattingArray[indexChat].last_message.is_bot = false;
        } else {
          chattingArray[indexChat].last_message.is_bot = true;
        }
      } else {
        chattingArray[indexChat].last_message.is_bot = data.is_bot;
      }

      store.dispatch("leftsectorModule/setChattingMessages", chattingArray);
    } else {
      state.newMessageBackup = data;
    }

    let searchArray = JSON.parse(JSON.stringify(state.searchMessages));
    let indexsearch = searchArray.findIndex((element) => {
      return element.id === parseInt(data.room_id);
    });
    if (indexsearch !== -1) {
      searchArray[indexsearch].last_message.message = data.message;
      searchArray[indexsearch].last_message.staff_id = data.staff_id;

      if (data.is_bot === undefined) {
        if (data.staff_id === null) {
          searchArray[indexsearch].last_message.is_bot = false;
        } else {
          searchArray[indexsearch].last_message.is_bot = true;
        }
      } else {
        searchArray[indexsearch].last_message.is_bot = data.is_bot;
      }

      store.commit("leftsectorModule/SET_SEARCHMESSAGE_DATA", searchArray);
    } else {
      state.newMessageBackup = data;
    }
  };

  return { state, setHeight, handleClickRefresh };
}
