<template>
  <div
    v-for="(chattingMessage, index) in chattingMessages"
    :key="index"
    :id="'chatting' + chattingMessage.id"
    class="row chatting-message-item cursor-pointer q-ml-none"
    :class="[
      customer.id === chattingMessage.id ? 'read ' : '',
      condition === 'false' ? 'full-width' : 'width-90',
    ]"
    :data-check="[chattingMessage.id, customer.id]"
    @click="
      handleClickGetChat(
        chattingMessage.id,
        chattingMessage.web_id,
        chattingMessage.staff_id,
        chattingMessage.is_read,
        chattingMessage.staff_name
      )
    "
    v-show="
      condition === 'true'
        ? (viewMode === 'all' && chattingMessage.staff_id === staff.id) ||
          (viewMode === 'pin' &&
            chattingMessage.is_pin === true &&
            chattingMessage.staff_id === staff.id) ||
          (viewMode === 'unread' &&
            chattingMessage.is_read === false &&
            chattingMessage.staff_id === staff.id) ||
          (viewMode === 'process' &&
            chattingMessage.is_process === true &&
            chattingMessage.staff_id === staff.id)
        : true
    "
  >
    <!-- (viewMode === 'end' && chattingMessage.staff_id !== staff.id) -->
    <!-- <div class="col-2 flex items-center q-pl-none"></div> -->
    <div class="col flex q-gutter-x-sm">
      <div class="flex items-center">
        <q-avatar size="lg" class="relative-position">
          <img :src="chattingMessage.customer.picture_url" />
          <div
            v-if="chattingMessage.is_pin === true"
            class="pin-icon absolute-bottom-right"
          ></div>
          <!-- <q-icon
          v-if="chattingMessage.is_pin === true"
          class="absolute-bottom-right"
          name="push_pin"
          size="xs"
          color="dark-green"
        ></q-icon> -->
        </q-avatar>
      </div>
      <div style="width: calc(100% - 54px)">
        <div
          ref="chattingItem"
          :id="'chattingItem' + chattingMessage.customer.id"
          class="font-semibold q-gutter-x-xs flex items-center full-width"
        >
          <div
            class="text-ellipsis q-ml-none"
            :style="{
              width:
                setChattingMessageWidth(chattingMessage.customer.id) === true
                  ? 'calc(100% - 55px)'
                  : '',
            }"
          >
            <span
              ref="chattingTextItem"
              :id="'chattingTextItem' + chattingMessage.customer.id"
              class="text-normal"
              >{{ chattingMessage.customer.display_name }}</span
            >
          </div>
          <div class="q-gutter-x-xs">
            <q-avatar
              v-if="chattingMessage.is_process === true"
              size="xs"
              color="dark-blue"
              text-color="white"
              class="text-avatar"
              style="font-size: 21px"
              >W</q-avatar
            >
            <q-avatar
              v-if="chattingMessage.is_read === false"
              size="xs"
              color="red"
              text-color="white"
              class="text-avatar"
              style="font-size: 21px"
              >N</q-avatar
            >
          </div>
        </div>
        <div class="font-regular row">
          <div class="col-12 text-ellipsis flex items-center">
            <q-icon
              v-if="
                chattingMessage.last_message !== null &&
                chattingMessage.last_message.staff_id === null &&
                chattingMessage.last_message.is_bot === false
              "
              name="account_circle"
              color="main-red"
              class="q-mr-xs"
            />
            <span
              class="text-ellipsis"
              :style="
                chattingMessage.last_message !== null &&
                chattingMessage.last_message.staff_id === null &&
                chattingMessage.last_message.is_bot === false
                  ? 'width: calc(100% - 18px)'
                  : 'width: 100%'
              "
              >{{
                chattingMessage.last_message !== null
                  ? chattingMessage.last_message.message.type === "text"
                    ? chattingMessage.last_message.message.text
                    : chattingMessage.last_message.message.type === "flex"
                    ? chattingMessage.last_message.message.altText
                    : chattingMessage.last_message.message.type === "sticker"
                    ? "[ สติ๊กเกอร์ ]"
                    : chattingMessage.last_message.message.type === "image" ||
                      chattingMessage.last_message.message.type ===
                        "staff_image"
                    ? "[ รูปภาพ ]"
                    : chattingMessage.last_message.message.type === "html"
                    ? "[ ข้อความกลาง ]"
                    : "[ Unsupport Message ]"
                  : "[ Unsupport Message ]"
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from "vue";
import { utilily } from "@/utilities/utility";
import { useStore } from "vuex";

export default {
  props: ["chattingMessages", "customer", "viewMode", "staff", "condition"],
  setup() {
    const { getChat } = utilily();
    const store = useStore();

    const state = reactive({
      count: null,
    });

    const chattingItem = ref([]);
    const chattingTextItem = ref([]);

    const handleClickGetChat = async (
      id,
      web_id,
      staff_id,
      is_read,
      staff_name
    ) => {
      getChat(id, web_id, staff_id, is_read);
      store.commit("centersectorModule/SET_CHATSTAFF_DATA", staff_name);
    };

    const setChattingMessageWidth = (id) => {
      const elemRow = chattingItem.value.find(
        (obj) => obj.id === "chattingItem" + id
      );
      const elemText = chattingTextItem.value.find(
        (obj) => obj.id === "chattingTextItem" + id
      );

      if (elemRow !== undefined || elemText !== undefined) {
        if (elemRow.offsetWidth - 54 < elemText.offsetWidth) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    return {
      ...toRefs(state),
      chattingItem,
      chattingTextItem,
      handleClickGetChat,
      setChattingMessageWidth,
    };
  },
};
</script>

<style lang="scss" scoped>
.chatting-message-item {
  display: flex;
  color: black;
  background-color: $white-green;
  padding: 0.5rem;
  border-radius: 0.75rem;
  &.read {
    border: 0.1rem solid #40ac7b;
  }
  & .profile-image {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
  & .badge-noti {
    background-color: #ff5151;
  }
}
</style>
