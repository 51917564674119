import { request, requestImg } from "./api";

export function getRoom(data) {
  const url = `/api/chatbot/rooms/${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function updateRoom(data) {
  const url = `/api/chatbot/rooms/${data}`;
  return request("put", url, {}, localStorage.getItem("token"));
}

export function sendText(data) {
  const url = `/api/chatbot/send`;
  return request("post", url, data, localStorage.getItem("token"));
}

export function getImage(data) {
  const url = `/api/chatbot/getimage${data}`;
  return requestImg("get", url, {}, localStorage.getItem("token"));
}

export function getStaffOnline() {
  const url = `/api/chatbot/getstaffonline`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function getStaff(data) {
  const url = `/api/chatbot/staff/${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function sendImage(data) {
  const url = `/api/chatbot/sendimage`;
  return requestImg("post", url, data, localStorage.getItem("token"));
}

export function sendSticker(data) {
  const url = `/api/chatbot/sendsticker`;
  return request("post", url, data, localStorage.getItem("token"));
}

export function resend(data) {
  const url = `/api/chatbot/resend`;
  return request("post", url, data, localStorage.getItem("token"));
}
