<template>
  <q-dialog v-model="isCustomer" persistent seamless>
    <q-card class="financialDialog" style="width: 500px; max-width: 50vw">
      <q-card-section
        class="row justify-start items-center header green font-semibold q-py-sm q-px-md"
      >
        <div class="">
          {{ customer.isChangeName === true ? "แก้ไขชื่อ" : "บันทึกช่วยจำ" }}
        </div>
        <q-btn
          @click="handleCloseDialog"
          class="absolute-right"
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>

      <q-card-section class="q-col-gutter-x-md q-pb-sm">
        <div class="q-gutter-y-md font-regular">
          <div>
            <q-input
              :dense="customer.isChangeName === true ? true : false"
              filled
              v-model="text"
              :type="customer.isChangeName === true ? 'text' : 'textarea'"
              :label="
                customer.isChangeName === true
                  ? 'ชื่อ'
                  : 'คุณสามารถเขียนบันทึกเกี่ยวกับผู้ใช้  โดยบันทึกจะแสดงให้คุณเห็นคนเดียว'
              "
              bg-color="white"
              color="black"
            />
          </div>
          <div class="flex justify-end">
            <q-btn
              @click="handleClickSendCustomer(true)"
              unelevated
              :color="
                customer.isChangeName === true
                  ? text !== ''
                    ? 'gd-green'
                    : 'light-grey'
                  : 'gd-green'
              "
              text-color="white"
              label="บันทึก"
              size="md"
              class="btn-radius"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
  <q-dialog v-model="isLoading">
    <q-card class="loading-dialog font-regular">
      <q-card-section> กำลังส่งข้อมูล ...</q-card-section>
    </q-card>
  </q-dialog>
  <ExceptionDialog
    :isException="isException"
    :exceptionMessage="exceptionMessage"
    @handleCloseDialog="handleCloseExceptionDialog"
  />
</template>

<script>
import { computed, reactive, toRefs, watch } from "vue";
import { putUser } from "@/services/rightsector-service";
import { useStore } from "vuex";
import ExceptionDialog from "./ExceptionDialog.vue";

export default {
  props: ["isCustomers", "customers"],
  emits: ["handleCloseDialog"],
  components: { ExceptionDialog },
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      isCustomer: false,
      text: "",
      customer: {},
      isLoading: false,
      isException: false,
      exceptionMessage: "",
      web_id: computed(() => store.state.leftsectorModule.web_id),
    });

    watch(
      () => [props.customers, props.isCustomers],
      ([customer, isCustomer]) => {
        state.customer = customer;
        state.isCustomer = isCustomer;
        if (customer.isChangeName === true) {
          state.text = customer.currentName;
        } else {
          state.text = customer.note !== null ? customer.note : "";
        }
      }
    );

    const handleCloseDialog = () => {
      emit("handleCloseDialog");
    };

    const handleClickSendCustomer = async (validate) => {
      if (validate === false) {
        return;
      }
      var data = {};
      if (state.customer.isChangeName === true) {
        data = { id: state.customer.id, data: { display_name: state.text } };
      } else {
        data = { id: state.customer.id, data: { note: state.text } };
      }
      state.isLoading = true;
      const response = await putUser(data);
      state.isLoading = false;
      if (response.status === "success") {
        store.dispatch("rightsectorModule/setCustomer", response.data);
        await store.dispatch("leftsectorModule/getNewMessages", {
          staff_id: "",
          web_id: state.web_id,
        });
        await store.dispatch("leftsectorModule/getChattingMessages", {
          web_id: state.web_id,
        });
        handleCloseDialog();
      } else if (response.status === "http_error") {
        state.isException = true;
        state.exceptionMessage = response.message;
      } else {
        state.isException = true;
        state.exceptionMessage =
          response.data?.descHtml !== undefined
            ? response.data.descHtml
            : response.message;
      }
    };

    const handleCloseExceptionDialog = () => {
      state.isException = false;
    };

    return {
      ...toRefs(state),
      handleCloseDialog,
      handleClickSendCustomer,
      handleCloseExceptionDialog,
    };
  },
};
</script>

<style lang="scss" scoped></style>
