<template>
  <q-header class="bg-white text-header">
    <q-toolbar>
      <div v-if="page !== 'login'" class="admin-online-div">
        <div
          @click="isShowAdminList = !isShowAdminList"
          ref="adminCount"
          class="admin-online-count font-semibold cursor-pointer flex items-center"
        >
          <div class="admin-icon bg-gd-green q-pa-xs">
            <q-icon name="support_agent" size="sm" color="white" />
          </div>

          &nbsp;
          <span>Online Staff : {{ staffOnlines.length }}</span>
        </div>
        <div
          v-if="isShowAdminList === true"
          ref="adminList"
          class="admin-list q-pa-md"
        >
          <q-scroll-area style="height: 300px">
            <div v-if="staffOnlines.length > 0" class="q-gutter-y-sm">
              <div
                v-for="(staffOnline, index) in staffOnlines"
                :key="index"
                class="admin-list-item q-py-sm q-px-md"
              >
                <div class="row items-center full-width">
                  <div class="col-2" style="min-width: 35px">
                    <q-avatar color="gd-green" text-color="white" size="md">
                      {{
                        staffOnline.name !== undefined
                          ? staffOnline.name.charAt(0).toUpperCase()
                          : "A"
                      }}
                    </q-avatar>
                  </div>
                  <div class="col-9">
                    <div
                      class="text-ellipsis full-width font-regular text-body1"
                    >
                      {{ staffOnline.name }}
                    </div>
                  </div>
                </div>

                <q-badge rounded color="gd-green" />
              </div>
            </div>
            <div
              v-else
              class="absolute-center full-width text-center text-black font-regular"
            >
              ไม่มี Admin ที่กำลังออนไลน์ในขณะนี้
            </div>
          </q-scroll-area>
        </div>
      </div>

      <q-toolbar-title class="text-center text-normal">
        ADMIN CHAT
      </q-toolbar-title>
      <div
        v-if="page !== 'login'"
        class="right-sector-header font-semibold q-gutter-x-md q-mr-md text-normal"
      >
        <div>
          <q-badge rounded :color="isOnline === true ? 'gd-green' : 'gd-red'" />
        </div>
        <div>{{ dateTime }}</div>
        <div><q-icon name="textsms" size="sm"></q-icon> {{ unReadLength }}</div>
        <div style="height: 2rem !important">
          <q-select
            @update:model-value="(val) => handleWebsChange(val)"
            rounded
            standout="bg-grey text-black"
            v-model="web_select"
            :options="websOption"
            option-value="id"
            option-label="name"
            emit-value
            map-options
            class="web-select"
          />
        </div>

        <q-chip>
          <q-avatar color="gd-green" text-color="white">
            {{
              staff.name !== undefined
                ? staff.name.charAt(0).toUpperCase()
                : "A"
            }}
          </q-avatar>
          {{ staff.name !== undefined ? staff.name : "Admin" }}
        </q-chip>
        <div>
          <q-icon
            @click="isShowSetting = !isShowSetting"
            name="settings"
            size="sm"
            class="cursor-pointer"
          ></q-icon>
        </div>
        <div
          v-if="isShowSetting === true"
          class="lists-tabs right font-regular"
        >
          <div class="list-item disabled">
            <q-icon name="account_circle" size="xs"></q-icon>
            <span> แก้ไขโปรไฟล์</span>
          </div>
          <div class="list-item disabled">
            <q-icon name="tune" size="xs"></q-icon>
            <span> ตั้งค่า</span>
          </div>
          <div @click="handleClickLogout" class="list-item">
            <q-icon name="logout" size="xs"></q-icon>
            <span> ออกจากระบบ</span>
          </div>
        </div>
      </div>
    </q-toolbar>
  </q-header>
  <q-dialog v-model="alert">
    <q-card class="rounded" style="width: 400px; max-width: 30vw">
      <q-card-section>
        <div class="font-medium text-h6 text-center">ต้องการออกจากระบบ?</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div class="font-regular text-center text-greys">
          คุณมีการสนทนาค้างไว้ : {{ chattingMessages.length }} การสนทนา
        </div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div class="flex q-col-gutter-x-sm justify-end font-medium">
          <div>
            <q-btn
              v-close-popup
              unelevated
              color="grey-95"
              text-color="white"
              label="ปิด"
              size="md"
              class="btn-radius full-width"
            />
          </div>
          <div>
            <q-btn
              v-close-popup
              @click="setLogout"
              unelevated
              color="gd-green"
              text-color="white"
              label="ยืนยัน"
              size="md"
              class="btn-radius full-width"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import { useStore } from "vuex";
import { Logout } from "../services/leftsector-service";
import { useRouter } from "vue-router";

export default {
  props: ["page"],
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      staff: computed(() => store.state.rightsectorModule.staff),
      unReadLength: computed(() => store.state.leftsectorModule.unReadLength),
      isOnline: computed(() => store.state.centersectorModule.isOnline),
      chattingMessages: computed(
        () => store.state.leftsectorModule.chattingMessages
      ),
      staffOnlines: computed(() => store.state.leftsectorModule.staffOnline),
      websOption: computed(() => store.state.leftsectorModule.webs),
      web_id: computed(() => store.state.leftsectorModule.web_id),
      web_select: 0,
      isShowSetting: false,
      isShowAdminList: false,
      alert: false,
      dateTime: "",
    });

    const adminList = ref(null);
    const adminCount = ref(null);

    onMounted(() => {
      liveDateTime();
      setDateTime();
      document.addEventListener("click", handleClickOutSide);
    });

    onBeforeUnmount(() => {
      clearInterval(window.dateTimeIntv);
    });

    watch(
      () => state.web_id,
      () => {
        state.web_select = state.web_id;
      }
    );

    const handleWebsChange = async (value) => {
      localStorage.setItem("web", value);
      store.commit("leftsectorModule/SET_WEB_ID_DATA", value);
    };

    const handleClickOutSide = (event) => {
      if (
        adminList.value &&
        !adminList.value.contains(event.target) &&
        adminCount.value &&
        !adminCount.value.contains(event.target)
      ) {
        state.isShowAdminList = false;
      }
    };

    const liveDateTime = () => {
      window.dateTimeIntv = setInterval(() => {
        setDateTime();
      }, 1000 * 30);
    };

    const setDateTime = () => {
      // Get the current date and time
      let now = new Date();

      // Get the day, month, and year from the date object
      let day = now.getDate().toString().padStart(2, "0");
      let month = (now.getMonth() + 1).toString().padStart(2, "0");
      let year = (now.getFullYear() + 543).toString().slice(-2);

      // Get the hour and minute from the date object
      let hour = now.getHours().toString().padStart(2, "0");
      let minute = now.getMinutes().toString().padStart(2, "0");

      // Format the date and time string
      let formattedDate = `${day}/${month}/${year}, ${hour}:${minute}`;

      state.dateTime = formattedDate;
    };

    const handleClickLogout = () => {
      if (state.chattingMessages.length > 0) {
        state.alert = true;
      } else {
        setLogout();
      }
    };

    const setLogout = async () => {
      const response = await Logout();
      console.log(response);
      if (response.status === "success") {
        clearInterval(window.staffIntv);
        clearInterval(window.chattimeIntv);
        router.replace({ name: "login" });
      }
    };

    return {
      ...toRefs(state),
      adminCount,
      adminList,
      handleWebsChange,
      handleClickLogout,
      setLogout,
    };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1366px) {
  .q-toolbar__title {
    display: none;
  }
}
.text-header {
  color: $grey-4f;
}
.right-sector-header {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  width: fit-content;
  align-items: center;
  font-size: 1rem;
}
.profile-sector {
  display: flex;
  align-items: center;
  background-color: $background;
  border-radius: 0.75rem;
  padding: 0.25rem 0.4375rem;
  & .profile-image {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }
}
.admin-online-count {
  background-color: $background;
  padding: 0.25rem 1rem;
  border-radius: 0.75rem;
}
.admin-list {
  position: absolute;
  top: 100%;
  width: 17%;
  min-width: 250px;
  background-color: #cfcfcf;
  border-radius: 1rem;
  & .admin-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 1rem;
  }
}
.admin-icon {
  border-radius: 50%;
}
</style>
