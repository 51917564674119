<template>
  <q-dialog v-model="isDepositDetail" persistent>
    <q-card class="financialDialog" style="width: 500px; max-width: 70vw">
      <q-card-section
        class="row justify-center items-center header green font-semibold q-pa-sm"
      >
        <div class="text-h6">ID : {{ depositDetail.code }}</div>
        <q-btn
          @click="handleCloseDialog"
          class="absolute-right"
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>

      <q-card-section class="row q-col-gutter-x-md">
        <div class="col font-regular relative-position">
          <img
            v-if="depositDetail.more_data.image_url"
            :src="depositDetail.more_data.image_url"
            class="full-width full-height"
            id="img-preview"
          />
          <div v-else class="font-regular absolute-center">ไม่มีรูปภาพสลิป</div>
        </div>
        <div class="col q-gutter-y-sm font-regular">
          <div>
            <span class="text-greys">User : </span>
            <span>{{ depositDetail.more_data.user }}</span>
          </div>
          <div>
            <span class="text-greys">บัญชี : </span>
            <span>{{ depositDetail.more_data.bank_acc }}</span>
          </div>
          <div>
            <span class="text-greys">ยอดเงิน : </span>
            <span>{{ numberWithCommas(depositDetail.amount) }} บาท</span>
          </div>
          <div>
            <span class="text-greys">เวลา : </span>
            <span>{{ depositDetail.more_data.transaction_time }}</span>
          </div>
          <div style="word-break: break-all">
            <span class="text-greys">หมายเหตุ : </span>
            <span>{{ depositDetail.more_data.note }}</span>
          </div>
          <div>
            <span class="text-greys">สถานะ : </span>
            <q-badge
              :color="
                depositDetail.status >= 900
                  ? 'normal-green'
                  : depositDetail.status >= 300
                  ? 'light-red'
                  : depositDetail.status >= 100
                  ? 'dark-orange'
                  : 'light-red'
              "
              class="q-py-xs q-px-sm justify-center"
            >
              {{
                depositDetail.status >= 900
                  ? "เรียบร้อย"
                  : depositDetail.status >= 300
                  ? "ยกเลิก"
                  : depositDetail.status >= 100
                  ? "รอดำเนินการ"
                  : "Unknown"
              }}
            </q-badge>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, reactive, toRefs, watch } from "vue";
import { getBank } from "@/services/rightsector-service";
import { utilily } from "@/utilities/utility";
import { useStore } from "vuex";

export default {
  props: ["isDepositDetails", "depositDetailData"],
  emits: ["handleCloseDialog"],
  setup(props, { emit }) {
    const { padNumber, numberWithCommas } = utilily();

    const store = useStore();

    const state = reactive({
      isDepositDetail: false,
      depositDetail: {},
      userLists: computed(() => store.state.rightsectorModule.userLists),
    });

    watch(
      () => props.isDepositDetails,
      (isDepositDetail) => {
        state.isDepositDetail = isDepositDetail;
        if (isDepositDetail === true) {
          state.depositDetail = props.depositDetailData;
          initData();
        }
      }
    );

    const initData = async () => {
      const userByName = state.userLists.find(
        (obj) => obj.user === state.depositDetail.more_data.user
      );
      if (userByName) {
        const req = `${userByName.id}/${state.depositDetail.more_data.bank_acc}`;
        const response = await getBank(req);
        if (response.status === "success") {
          state.depositDetail.more_data.bank_acc =
            response.data.bankName + " " + response.data.accNumber;
        }
      } else {
        state.depositDetail.more_data.bank_acc = `[ไม่พบบัญชีธนาคาร]`;
      }

      const date = new Date(
        state.depositDetail.more_data.transaction_time * 1000
      );

      // Format the date string as desired
      state.depositDetail.more_data.transaction_time = `${date.getFullYear()}-${padNumber(
        date.getMonth() + 1
      )}-${padNumber(date.getDate())} ${padNumber(date.getHours())}:${padNumber(
        date.getMinutes()
      )}`;
    };

    const handleCloseDialog = () => {
      emit("handleCloseDialog");
    };

    return {
      ...toRefs(state),
      handleCloseDialog,
      numberWithCommas,
    };
  },
};
</script>

<style lang="scss" scoped></style>
