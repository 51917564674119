<template>
  <q-layout id="layout" view="hHh lpR fFf" class="bg">
    <headerComponent page="normal" />

    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import headerComponent from "@/components/Header.vue";

export default {
  name: "LayoutDefault",
  components: { headerComponent },
  setup() {
    return {
      leftDrawerOpen: ref(false),
    };
  },
};
</script>

<style lang="scss" scoped></style>
